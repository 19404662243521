import { IconButton, InputAdornment, TextField } from '@material-ui/core'
import React from 'react'
import useStyles from './styles';
import { CloseOutlined, SearchOutlined } from '@material-ui/icons';
import clsx from 'clsx';

const SearchBar = (props) => {
  const { query, handleSearch, placeholder, handleClearSearch } = props;
  const classes = useStyles();

  return (
    <TextField
      autoFocus
      className={classes.searchField}
      id='searchBar'
      onChange={event => handleSearch(event.target.value)}
      placeholder={placeholder}
      value={query}
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined color="secondary" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              id='clearSearchButton'
              edge="end"
              onClick={handleClearSearch}
              size="small"
              className={
                clsx({
                  [classes.visuallyHidden]: (query === null || query === '')
                })
              }
            >
              <CloseOutlined />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}

export default SearchBar