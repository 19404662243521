import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  searchField: {
    width: '100%',
    padding: theme.spacing(1),
    margin: theme.spacing(0),
    '& .MuiInputBase-root': {
      height: theme.spacing(5.625)
    }
  },
  visuallyHidden: {
    visibility: 'hidden'
  },
}));