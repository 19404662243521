import clsx from 'clsx';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { EventsFilterContext } from '../../../context/eventsFIlterContext';
import { ADD_FILTER } from '../../../reducer/eventsFilterReducer';
import { getAccessPoint, getAccessPointListCount } from '../../../service/accessPointsApi';
import { ASCENDING, EVENT_FILTER_COMPONENT_ORDER, EVENT_FILTER_COMPONENT_PAGINATION, EVENT_FILTER_COMPONENTS, NAME } from '../../../utility/constants';
import { getEventInitialLabel, getInitialEventFilterValues } from '../../../utility/event';
import Chip from '../chip';
import Dropdown from '../drop-down';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

const AccessPoints = (props) => {
  const { overflowItems, chipRefs } = props;
  
  const { t } = useTranslation();
  
  const { state: stateFilter, dispatch } = useContext(EventsFilterContext);
  
  const name = EVENT_FILTER_COMPONENTS.ACCESS_POINTS;
  const componentOrder = EVENT_FILTER_COMPONENT_ORDER.indexOf(name);
  
  const { accessPoints: accessPointsState = [] } = stateFilter;

  const initialAccessPoints = getInitialEventFilterValues(accessPointsState);

  const defaultLabel = t(`events-page-filter.${name}`);
  const initialLabel = getEventInitialLabel(initialAccessPoints, defaultLabel, t('events-page-filter.multipleAccessPointsLabel', { count: initialAccessPoints?.length }));

  const initialList = { parent: null, items: [], isMain: true };

  const classes = useStyles();
  const [status, setStatus] = useState(initialAccessPoints.length);
  const [selectedValues, setSelectedValues] = useState(initialAccessPoints);
  const [list, setList] = useState(initialList);
  const [label, setLabel] = useState(initialLabel);
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(EVENT_FILTER_COMPONENT_PAGINATION.PAGE);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const handleDelete = () => {
    setSelectedValues([]);
    setLabel(defaultLabel);
    dispatch({ type: ADD_FILTER, payload: {...stateFilter, accessPoints: [] } })
    setStatus(false);
  };

  const handleSelect = (value, children = []) => {
    let updatedSelections = [...selectedValues];
    const isValueSelected = updatedSelections.find(item => value.id === item.id)
    
    if (isValueSelected) {
      updatedSelections = updatedSelections.filter(item => item.id !== value.id);
    } else {
      updatedSelections.push(value);
    }
    
    setSelectedValues(updatedSelections);
  };  

  const handleSearch = (value) => {
    setKeyword(value);
    setPage(EVENT_FILTER_COMPONENT_PAGINATION.PAGE);
  };

  const handleList = (item) => {
    setList(item);
  }

  const handleClose = () => {
    const resetAccessPoints = getInitialEventFilterValues(accessPointsState);
    const newLabel = getEventInitialLabel(resetAccessPoints, defaultLabel, t('events-page-filter.multipleAccessPointsLabel', { count: resetAccessPoints?.length }));
    
    setSelectedValues(resetAccessPoints);
    setLabel(newLabel);
    setStatus(!!resetAccessPoints.length);
  }

  const isArrayLocationsEqual = (selectedLocations, prevLocations) => {
    if (selectedLocations.length !== prevLocations.length) {
      return false;
    }

    return selectedLocations.every((location, index) => location.id === prevLocations[index].id);
  };

  const handleSubmit = () => {
    const hasChanged = !isArrayLocationsEqual(selectedValues, initialAccessPoints);
    if (!hasChanged) {
      return;
    }
    const accessPoints = selectedValues.map(value => {
      return {
        id: value.id, 
        label: value.label
      }
    });

    dispatch({ type: ADD_FILTER, payload: {...stateFilter, accessPoints } })

    let newLabel = defaultLabel;
    if (selectedValues?.length > 1) {
      newLabel = t('events-page-filter.multipleAccessPointsLabel', { count: selectedValues?.length });
    } else if (selectedValues.length === 1) {
      newLabel = selectedValues[0]?.label; 
    }

    setLabel(newLabel);
    setStatus(hasChanged);
  };

  const getData = useCallback(async() => {
    setIsLoading(true);
    try {
      const response = await getAccessPoint(keyword, EVENT_FILTER_COMPONENT_PAGINATION.SIZE, page, NAME, ASCENDING, []);
      const { accessPoints } = response;

      const countResponse = await getAccessPointListCount(keyword, [], EVENT_FILTER_COMPONENT_PAGINATION.SIZE, page);
      const pagination = countResponse.page;
      const { totalElements, totalPages, number } = pagination;

      const newAccessPoints = accessPoints.map((accessPoint) => {
        const { name, id } = accessPoint;
        return {
          label: name,
          id: id,
          children: []
        }
      });

      setList(prev => {
        const updatedItems = number === 0 ? newAccessPoints : [...prev.items, ...newAccessPoints];
        return { 
          parent: null, 
          items: updatedItems, 
          isMain: true 
        }
      });
      setHasMore(totalElements > 0 && page < totalPages - 1);

    } catch(e) {
      console.log('Error', e)
    } finally {
      setIsLoading(false);
    }
  }, [keyword, page]);

  const handleScroll = () => {
    setPage((prev) => prev + 1);
  }

  useEffect(() => {
    let delayDebounce;

    if (keyword) {
      delayDebounce = setTimeout(() => {
        getData();
      }, 500);
    } else {
      getData();
    }

    return () => {
      delayDebounce && clearTimeout(delayDebounce);
    }
  }, [keyword, page, getData]);

  useEffect(() => {
    setSelectedValues(accessPointsState);
    let initialLabel = getEventInitialLabel(accessPointsState, defaultLabel, t('events-page-filter.multipleAccessPointsLabel', { count: accessPointsState?.length }))
    setLabel(initialLabel);
    setStatus(accessPointsState.length);
  }, [accessPointsState, defaultLabel, t]);
  
  return (
    <Chip
      handleDelete={handleDelete}
      showCloseIcon={status}
      containerStyle={clsx(
        !status ? classes.inactiveChip : '',
        overflowItems?.includes(componentOrder) ? classes.hiddenChip : '',
        !chipRefs && classes.overflow
      )}
      status={selectedValues.length}
      ref={(ref) => {
        if (ref && chipRefs) chipRefs.current[componentOrder] = ref;
      }}
    >
      <Dropdown
        label={label}
        list={list}
        handleSubmit={handleSubmit}
        handleSearch={handleSearch}
        handleScroll={handleScroll}
        handleSelect={handleSelect}
        selectedValues={selectedValues}
        handleList={handleList}
        handleClose={handleClose}
        isLoading={isLoading}
        hasMore={hasMore}
      />
    </Chip>
  );
};

export default AccessPoints;
