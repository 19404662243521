import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  dateContainer: {
    position: 'absolute',
    background: colors.PRIMARY_LIGHT,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.25),
    paddingLeft: theme.spacing(1)
  },
  main: {
    '& .react-daterange-picker__wrapper': {
      border: "none",
      borderRadius: theme.spacing(0.5),
    },
    '& .react-daterange-picker__calendar--open': {
      zIndex: 100
    },
    '& .react-calendar': {
      border: "none",
      borderRadius: "5.307px",
      boxShadow: "0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.30)",
    },
    '& .react-calendar__navigation__label__labelText, .react-calendar__navigation__arrow': {
      fontWeight: 'bold'
    },
    '& .react-calendar__tile, .react-calendar__month-view__days__day--neighboringMonth, .react-calendar__month-view__days__day--weekend': {
      color: colors.PRIMARY_TEXT
    },
    '& .react-calendar__tile--hasActive, .react-calendar__tile--range': {
      color: colors.PRIMARY_TEXT,
      backgroundColor : "#A5D6FF",
      fontWeight: 'bold'
    },
    '& .react-calendar__tile:disabled': {
      color: colors.DISABLED_TEXT
    },
    '&:hover, &:focus': {
      cursor: "pointer"
    }
  },
}));