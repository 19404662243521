import { Badge, Box, Button, Divider, IconButton, Popover } from "@material-ui/core";
import { Cancel, MoreHoriz } from "@material-ui/icons";
import clsx from "clsx";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Trans } from 'react-i18next';
import { EventsFilterContext } from "../../../context/eventsFIlterContext";
import useStyles from "./styles";

const ClearButton = ({ onClear, classes }) => (
  <Box className={classes.clearButtonContainer}>
    <IconButton onClick={onClear} className={classes.iconButton} aria-label="clear">
      <Cancel />
    </IconButton>
  </Box>
);

const OverflowActionButtons = (props) => {
  const { isOverflowing, overflowItems, isSubmitDisabled, classes, eventFilterEntities, handleSubmit } = props;
  const { state } = useContext(EventsFilterContext);
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeChipCount, setActiveChipCount] = useState(0);
  const [components, setComponents] = useState([]);
  const spanRef = useRef();
  
  // The location dropdown refs component starts at index 2. The search button and date range are at index 0 and 1, respectively.
  const startingIndex = 2;

  const handleClick = () => setAnchorEl(spanRef.current);
  
  const onClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!overflowItems.length) {
      setAnchorEl(null);
    }
  }, [overflowItems.length]);

  const handleFilterComponents = useCallback(() => {
    const filteredHiddenComponents = eventFilterEntities.filter((component, index) => {
      return overflowItems.find(item => item === (index + startingIndex));
    });
    setComponents(filteredHiddenComponents);
  }, [overflowItems, eventFilterEntities]);

  const countActiveChips = useCallback(() => {
    const active = components.filter(component => {
      return state[component.parameter]?.length;
    });
    setActiveChipCount(active.length);
  }, [components, state]);

  useEffect(() => {
    countActiveChips();
  }, [components.length, countActiveChips, state]);

  useEffect(() => {
    handleFilterComponents();
  }, [overflowItems.length, handleFilterComponents, state]);

  return (
    <>
      <Divider
        className={clsx(classes.divider, !isOverflowing && classes.visuallyHidden)}
        orientation="vertical"
      />
      <Box
        className={clsx(classes.actionContainer, !isOverflowing && classes.actionButtonContainer)}
      >
        {overflowItems.length ? (
          <>
            <IconButton ref={spanRef} onClick={handleClick} className={clsx(classes.iconButton, !isOverflowing && classes.visuallyHidden)} aria-label="more">
              {activeChipCount ? (
                <Badge badgeContent={`+${activeChipCount}`}>
                  <MoreHoriz />
                </Badge>
              ) : (
                <MoreHoriz />
              )}
            </IconButton>
            <Popover
              open={Boolean(anchorEl)}
              PaperProps={{className: classes.popOver}}
              anchorEl={anchorEl}
              onClose={onClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              {components.map((entity) => (
                React.cloneElement(entity.component)
              ))}
            </Popover>
          </>
        ): (
          <></>
        )}
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitDisabled} className={classes.submitButton}>
          <Trans i18nKey={'events-page-filter.viewAllEventsLabel'} />
        </Button>
      </Box>
    </>
  )
};

const ActionButtons = ({
  isOverflowing,
  overflowItems,
  isSubmitDisabled,
  isSearchEnabled,
  keyword,
  handleClearKeyword,
  eventFilterEntities,
  handleSubmit
}) => {
  const classes = useStyles();

  return isSearchEnabled && keyword ? (
    <ClearButton onClear={handleClearKeyword} classes={classes} />
  ) : (
    !isSearchEnabled && (
      <OverflowActionButtons 
        isOverflowing={isOverflowing} 
        overflowItems={overflowItems} 
        isSubmitDisabled={isSubmitDisabled} 
        classes={classes}
        eventFilterEntities={eventFilterEntities}
        handleSubmit={handleSubmit}
      />
    )
  );
};

export default ActionButtons;
