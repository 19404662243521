import React, { memo, useCallback, useState, useRef, useEffect } from 'react';
import FilterBar from './filter-bar';

const MemoizedFilterBar = memo(FilterBar);

const EventFilter = ({ onSubmit, handlePermissions }) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [overflowItems, setOverflowItems] = useState([]);
  const containerRef = useRef(null);
  const chipRefs = useRef([]);
  const debounceTimeout = useRef(null);
  
  const checkOverflow = useCallback(() => {
    if (!containerRef.current || !chipRefs.current.length) return;
    
    const containerWidth = containerRef.current.clientWidth;
    let totalWidth = 0;
    let overflowing = new Set();

    for (let index = 0; index < chipRefs.current.length; index++) {
      const chip = chipRefs.current[index];
      if (!chip) continue;

      totalWidth += chip.offsetWidth;
      if (totalWidth > containerWidth) {
        for (let i = index; i < chipRefs.current.length; i++) {
          overflowing.add(i);
        }
        break;
      }
    }

    const newOverflowItems = Array.from(overflowing);
    setIsOverflowing(newOverflowItems.length > 0);
    setOverflowItems((prevItems) => {
      if (JSON.stringify(prevItems) !== JSON.stringify(newOverflowItems)) {
        return newOverflowItems;
      }
      return prevItems;
    });
  }, []);

  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        requestAnimationFrame(checkOverflow);
      }, 100);
    });

    resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [checkOverflow]);

  return (
    <MemoizedFilterBar
      isOverflowing={isOverflowing}
      overflowItems={overflowItems}
      containerRef={containerRef}
      chipRefs={chipRefs}
      onSubmit={onSubmit}
      handlePermissions={handlePermissions}
    />
  );
};

export default EventFilter;