import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  dropDowncontainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  label: {
    fontSize: theme.spacing(1.75),
    color: colors.DARK_GRAY_TEXT,
    padding: theme.spacing(0),
  },
  container: {
    width: theme.spacing(45),
  },
  content: {
    overflowY: 'auto',
    maxHeight: theme.spacing(55),
    height: 'fit-content',
    '& .MuiList-root': {
      padding: theme.spacing(0)
    },
    '& .MuiTypography-root': {
      color: colors.DARK_GRAY_TEXT
    },
    '& .MuiFormControlLabel-root': {
      marginLeft: theme.spacing(0),
      padding: theme.spacing(1)
    }
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0),
    '&:hover': {
      backgroundColor : colors.PRIMARY_LIGHT,
      boxShadow       : '0 0 0 100vmax ' + colors.PRIMARY_LIGHT,
      clipPath        : 'inset(0 -100vmax)'
    },
    '& .Mui-checked': {
      color: colors.PRIMARY,
    },
    '& .MuiCheckbox-indeterminate': {
      color: colors.PRIMARY
    }
  },
  previous: {
    justifyContent: 'flex-start',
    padding: theme.spacing(1),
    '& .MuiTypography-root': {
      color: colors.PRIMARY
    },
    '& .MuiButtonBase-root': {
      color: colors.PRIMARY
    },
    '&:hover': {
      backgroundColor : colors.PRIMARY_LIGHT,
      boxShadow       : '0 0 0 100vmax ' + colors.PRIMARY_LIGHT,
      clipPath        : 'inset(0 -100vmax)'
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
    '& .MuiButtonBase-root': {
      width: 'fit-content',
      padding: theme.spacing(1)
    }
  },
  loadField : {
    alignContent  : 'center',
    alignItems    : 'center',
    display       : 'flex',
    flexDirection : 'column',
    justifyContent: 'space-evenly',
    paddingRight  : theme.spacing(1),
  },
  loading: {
    marginLeft: theme.spacing(-0.5),
    marginTop : theme.spacing(1.5),
  },
}));