import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    height: '50px',
    boxShadow: theme.shadows[1] + ' !important'
  },
  inputContainer: {
    width: '80%',
    display: 'flex',
    overflow: 'hidden'
  },
  input: {
    marginLeft: theme.spacing(1),
    width: '94%',
    '& ::placeholder': {
      fontSize: theme.spacing(1,75),
      fontStyle: 'italic'
    }
  },
  iconButton: {
    padding: 10,
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  overFlowActionButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  clearButtonContainer: {
    width: '10%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  divider: {
    height: 28,
    margin: 6,
  },
  submitButton: {
    height: 'fit-content',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  actionButtonContainer: {
    justifyContent: 'flex-end'
  },
  visuallyHidden: {
    visibility: 'hidden'
  },
  chipContiner: {
    display: 'flex',
    alignItems: 'center',
  },
  chipEntities: {
    display: 'flex',
    marginLeft: theme.spacing(27.24)
  },
  searchContent: {
    marginLeft: theme.spacing(1)
  },
  inactiveChip: {
    background: 'transparent !important',
  },
  hiddenChip: {
    visibility: 'hidden !important'
  }
}));