import { Container } from '@material-ui/core';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EnhancedTable, { createColumn } from '../../components/enhanced-table';
import EventTypeColumn from '../../components/event-type-column';
import TimeColumn from '../../components/time-column';
import { EventsFilterContext } from '../../context/eventsFIlterContext';
import { LocationContext } from '../../context/locationContext';
import useParams from '../../hooks/useParams';
import { ADD_FILTER, initialState, UPDATE_EVENTS } from '../../reducer/eventsFilterReducer';
import { getEventList, getEventListCount } from '../../service/eventsApi';
import { ACCESS_POINTS_MODULE, ALL, API_REQUEST_ERROR_MESSAGE, ASCENDING, CONTROLLERS_MODULE, DATETIME_FORMAT, DESCENDING, EVENT_FILTER_COMPONENT_PAGINATION, EVENTS_MODULE, EVENTS_TITLE, GRANTED, USERS_MODULE } from '../../utility/constants';
import { createAccessPointObj, createControllerObj, createReaderObj, createUserObj, parseJSONParam } from '../../utility/event';
import { formatDate, getInitialLocations, parseParams } from '../../utility/helper';
import EnhancedDrawer from './events-drawer';
import useStyles from './styles';

const columns = [
  createColumn('id', 'ID', false, 'numeric', true),
  createColumn('time', 'events-page.timeColumn', true, 'component', false, true, true),
  createColumn('event', 'events-page.eventColumn', true, 'component', false, false, true),
  createColumn('controller', 'events-page.controllerColumn', true, 'string', false, false, false, CONTROLLERS_MODULE),
  createColumn('accessPoint', 'events-page.accessPointColumn', true, 'string', false, false, false, ACCESS_POINTS_MODULE),
  createColumn('reader', 'events-page.readerColumn', true, 'string', false, false, false, ACCESS_POINTS_MODULE),
  createColumn('user', 'events-page.userColumn', true, 'string', false, false, false, USERS_MODULE),
  createColumn('location', 'events-page.locationColumn', true, 'string'),
];

const Events = (props) => {
  const { showToaster, handlePermissions, newEvent, setNewEvent } = props;
  
  const { t } = useTranslation();
  const classes = useStyles();

  const { state : stateFilter, dispatch } = useContext(EventsFilterContext);
  const [searchParams, setSearchParams] = useParams(initialState);

  const { state : locationState }  = useContext(LocationContext);

  const { selectedLocations } = locationState;
  const { sort, listType: listTypeParam, size: sizeParam, page: pageParam } = searchParams;

  const [isLoading, setIsLoading]                     = useState(false);
  const [isTotalItemsLoading, setIsTotalItemsLoading] = useState(false);
  const [events, setEvents]                           = useState(stateFilter.events);
  const [totalEvents, setTotalEvents]                 = useState(0);
  const [selectedRow, setSelectedRow]                 = useState(null);
  const [eventDrawerState, setEventDrawerState]       = useState({
    id    : null,
    isOpen: false
  });

  let countCancelTokenRef = useRef(null); 
  let listCancelTokenRef = useRef(null); 
  let hasFetched = useRef(null);

  const orderBy = parseParams(sort)[0];
  const order = parseParams(sort)[1];
  const listType = listTypeParam?.toLowerCase();
  const size = parseInt(sizeParam);
  const [page, setPage] = useState(parseInt(pageParam));
  
  const title = listType === GRANTED ? EVENTS_TITLE.ACCESS_GRANTED : EVENTS_TITLE.CONTROLLER_EVENTS; 

  const getTypesSubTypes = useCallback((values) => {
    const typeSubTypeParams = parseParams(values);
    return typeSubTypeParams.join();
  }, []);

  const getParams = (values) => {
    const newValues = parseParams(values).map(value => value.label);
    return newValues.join();
  }

  const sortEvents = useCallback((eventA, eventB, order) => {
    const dateA = formatDate(eventA.dateCreated, DATETIME_FORMAT.EUROPEAN_DATE_TIME);
    const dateB = formatDate(eventB.dateCreated, DATETIME_FORMAT.EUROPEAN_DATE_TIME);
  
    if (dateA === dateB) {
      if (order === DESCENDING) {
        return eventA.eventId > eventB.eventId ? -1 : 1;
      } else if (order === ASCENDING) {
        if (eventA.type === eventB.type) {
          return eventA.eventId <= eventB.eventId ? -1 : 1;
        } else {
          return eventA.eventId > eventB.eventId ? -1 : 1;
        }
      }
    }
  
    return 0;
  }, []);

  const formatEvents = useCallback((event) => {
    const { eventId, type, subType, user, dateCreated, accessPoint, location, controller, reader } = event;
    const controllerObj = createControllerObj(controller);
    
    const accessPointObj = createAccessPointObj(accessPoint);

    const readerObj = createReaderObj(reader, controller);

    const userObj = createUserObj(user);

    return {
      id: eventId,
      time: <TimeColumn value={dateCreated} multiLine={true}/>,
      event:  <EventTypeColumn type={type} subType={subType}/>,
      controller: controllerObj,
      accessPoint: accessPointObj,
      reader: readerObj,
      user: userObj,
      location: location?.name
    };
  }, []);

  const getFilterParams = useCallback((params) => {
    const locationIds = parseParams(params.locationIds)?.map(value => value.id);
    return {
      ...params,
      locationIds: locationIds.join(),
      subTypes: getTypesSubTypes(params.subTypes),
      deviceIds: getParams(params.deviceIds),
      accessPoints: getParams(params.accessPoints),
      credentialNumbers: getParams(params.credentialNumbers),
      types: ''
    }
  }, [getTypesSubTypes])
  
  const getCountFilterParams = useCallback((params) => {
    const locationIds = parseParams(params.locationIds)?.map(value => value.id);
    return {
      subTypes: getTypesSubTypes(params.subTypes),
      deviceIds: getParams(params.deviceIds),
      accessPoints: getParams(params.accessPoints),
      credentialNumbers: getParams(params.credentialNumbers),
      keyword: params.keyword,
      until: params.until,
      from: params.from,
      locationIds: locationIds.join(),
      types: ''
    }
  }, [getTypesSubTypes]);

  const getEventsListCount = useCallback(async (params) => {
    setIsTotalItemsLoading(true);
    
    if (countCancelTokenRef.current) {
      countCancelTokenRef.current?.cancel();
    }
    countCancelTokenRef.current = axios.CancelToken.source();

    const filterParam = getCountFilterParams(params);
    delete filterParam.events

    try {
      const totalResponse = await getEventListCount(filterParam, countCancelTokenRef.current);
      setTotalEvents(totalResponse.page.totalElements);
      setPage(totalResponse.page.number);
      setIsTotalItemsLoading(false);
    } catch (error){
      if (!axios.isCancel(error)) {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
        setIsTotalItemsLoading(false);
      }
    }
  }, [t, showToaster, getCountFilterParams]);

  const getEventsData = useCallback(async (params) => {
    if (listCancelTokenRef.current) {
      listCancelTokenRef.current.cancel();
    }
    listCancelTokenRef.current = axios.CancelToken.source();

    const filterParam = getFilterParams(params);
    delete filterParam.listType;
    delete filterParam.events
    
    try {
      const response = await getEventList(filterParam, listCancelTokenRef.current);

      const eventList = response.events
        .sort((eventA, eventB) => sortEvents(eventA, eventB, order))
        .map(formatEvents);

      setEvents(eventList);
      setPage(filterParam.page);
      dispatch({ type: UPDATE_EVENTS, payload: eventList });
      setIsLoading(false);
    } catch (error){
      if (!axios.isCancel(error)) {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
        setIsLoading(false);
      }
    }
  }, [t, showToaster, getFilterParams, sortEvents, formatEvents, dispatch, order]);

  const handleEventsData = useCallback((params) => {
    setIsLoading(true);
    getEventsData(params);
  }, [getEventsData]);

  const handleWSMessage = useCallback(() => {
    getEventsData(stateFilter);
    // Add 1 to the total count when there are new events, preventing an additional call
    setTotalEvents(prevCount => prevCount + EVENT_FILTER_COMPONENT_PAGINATION.PAGE);
    setNewEvent(false);
  }, [getEventsData, setNewEvent, stateFilter]);

  useEffect(() => {
    const globalLocations = getInitialLocations(selectedLocations);
    const locations = searchParams.locationIds?.length
      ? parseJSONParam(searchParams.locationIds)
      : globalLocations;
  
    const newState = {
      subTypes: parseJSONParam(searchParams.subTypes),
      accessPoints: parseJSONParam(searchParams.accessPoints),
      deviceIds:parseJSONParam(searchParams.deviceIds),
      credentialNumbers: parseJSONParam(searchParams.credentialNumbers),
    };

    // Store URL parameters in the state reducer
    dispatch({ 
      type: ADD_FILTER, 
      payload: { ...searchParams, ...newState, locationIds: locations }
    });

    // Fetch Events Data initially with initial state reducer and URL Params
    if (!hasFetched.current) {
      handleEventsData({ ...searchParams, ...newState, locationIds: locations });
      getEventsListCount({ ...searchParams, ...newState, locationIds: locations });
      hasFetched.current = true;
    }
  }, [dispatch, handleEventsData, getEventsListCount, searchParams, selectedLocations]);

  useEffect(() => {
    if (newEvent && hasFetched.current) {
      handleWSMessage();
    }
  }, [newEvent, handleWSMessage]);

  const handlePaginationParams = (newParams) => {
    const stateValues = {
      subTypes: JSON.stringify(stateFilter.subTypes),
      accessPoints: JSON.stringify(stateFilter.accessPoints),
      deviceIds: JSON.stringify(stateFilter.deviceIds),
      credentialNumbers: JSON.stringify(stateFilter.credentialNumbers),
    }

    let newSearchParams = Object.fromEntries(
      Object.entries(stateValues).map(([key, value]) => {
        const parsedValue = JSON.parse(value);
        if (!parsedValue || parsedValue.length === 0) {
          return [key, ''];
        }
  
        return [key, value];
      })
    );
    newSearchParams = {
      ...initialState,
      ...stateFilter,
      ...newSearchParams,
      locationIds: searchParams.locationIds,
    }
    delete newSearchParams.listType;
    delete newSearchParams.types;
    
    dispatch({ 
      type: ADD_FILTER, 
      payload: { ...stateFilter, ...newParams }
    });
    setSearchParams({...newSearchParams, ...newParams });
    handleEventsData({...stateFilter, ...newParams });
  }

  const handleChangePage = (newPage) => {
    const page = newPage + EVENT_FILTER_COMPONENT_PAGINATION.PAGE; 
    handlePaginationParams({ page });
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    const newParams = {
      size: newRowsPerPage,
      page: EVENT_FILTER_COMPONENT_PAGINATION.PAGE
    }
    handlePaginationParams(newParams);
  }

  const handleSort = (newOrderBy, newOrder) => {
    const newParams = {
      sort: `${newOrderBy},${newOrder}`, 
      page: EVENT_FILTER_COMPONENT_PAGINATION.PAGE
    }
    handlePaginationParams(newParams);
  }

  const handleRowClick = (id) => {
    setEventDrawerState({
      id,
      isOpen: true
    })
    setSelectedRow(id);
  }

  const handleCloseDrawer = () => {
    setEventDrawerState({
      id: null,
      isOpen: false
    });
  };

  useEffect(() => {
    if (!eventDrawerState.isOpen) {
      setSelectedRow(null);
    }
  }, [eventDrawerState]);

  const eventDrawerProps = {
    eventDrawerState,
    showToaster,
    onClose: handleCloseDrawer
  }

  const handleFilter = (params) => {
    handleEventsData(params);
    getEventsListCount(params);
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <EnhancedDrawer {...eventDrawerProps} />
      <EnhancedTable
        title={t(title)}
        columns={columns}
        data={events}
        isLoading={isLoading}
        isTotalItemsLoading={isTotalItemsLoading}
        keyword={''}
        label={EVENTS_MODULE}
        module={EVENTS_MODULE}
        onChangePage={handleChangePage}
        onClearSearch={() => {}}
        onRowsPerPageChange={handleRowsPerPageChange}
        onSearch={() => {}}
        onSort={handleSort}
        orderBy={orderBy}
        order={order}
        page={page}
        rowsPerPage={size}
        totalItems={totalEvents}
        listType={ALL.toLowerCase()}
        handlePermissions={handlePermissions}
        handleRowClick={handleRowClick}
        selectedRow={selectedRow}
        onFilter={handleFilter}
      />
    </Container>
  )
}

export default Events