import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  inactiveChip: {
    background: 'transparent !important',
  },
  hiddenChip: {
    visibility: 'hidden !important'
  },
  overflow: {
    marginRight: '0px !important',
    borderRadius: '0px !important'
  }
}));