import moment from "moment";
import { AADDC_USERS, CONTROLLER_DEVICE_TYPE, CONTROLLER_HOUSING, DATE_FORMAT, DATE_FORMAT_YYYY_MM_DD, DATETIME_FORMAT, DAY, LANGUAGE_EN, LDIF, YEAR } from "./constants";
import images from "./images";

/////////////////////////////
// createRandomCharacters()
/////////////////////////////
export const createRandomCharacters = (length) => {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

/////////////////////////////
// isEmpty()
/////////////////////////////
export const isEmpty = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

/////////////////////////////
// urlParamToJSON()
/////////////////////////////
export const urlParamToJSON = (url) => {
  return JSON.parse('{"' + url.replace(/&/g, '","').replace(/=/g, '":"') + '"}', (key, value) => { return key === "" ? value : decodeURIComponent(value) })
}

/////////////////////////////
// formatPhoneNumber()
/////////////////////////////
export const formatPhoneNumber = (value, previousValue) => {
  // return nothing if no value
  if (!value) return value;

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {

    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
}

/////////////////////////////
// getRandomNumber()
/////////////////////////////
export const getRandomNumber = (min, max) => {
  return Math.random() * (max - min) + min;
}

/////////////////////////////
// setLocalStorageItem()
/////////////////////////////
export const setLocalStorageItem = (storageKey, state) => {
  localStorage.setItem(storageKey, JSON.stringify(state));
}

/////////////////////////////
// getLocalStorageItem()
/////////////////////////////
export const getLocalStorageItem = (storageKey) => {
  const savedState = localStorage.getItem(storageKey);
  try {
    if (!savedState) {
      return undefined;
    }
    return JSON.parse(savedState ?? '{}');
  } catch (e) {
    return undefined;
  }
}

//////Delete Modal - List of Associated
export const formatAssociated = (arr, t) => {
  if (arr.length > 1) {
    let lastItem = arr.pop();
    return `${arr} ${t('and')} ${lastItem}`;
  } else {
    return `${arr}`
  }
}

export const isArrayEqual = (array1, array2) => {
  // If length is not equal
  if (array1.length !== array2.length) {
    return false;
  } else {
    // Comparing each element of array
    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }
    return true;
  }
}

/////////////////////////////
// inWords()
/////////////////////////////
export const inWords = (num, language) => {
  const ones    = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
  const tens    = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
  const onesDE  = ['', 'ein', 'zwei', 'drei', 'vier', 'fünf', 'sechs', 'sieben', 'acht', 'neun', 'zehn', 'elf', 'zwölf', 'dreizehn', 'vierzehn', 'fünfzehn', 'sechszehn', 'siebzehn', 'achtzehn', 'neunzehn'];
  const tensDE  = ['', '', 'zwanzig', 'dreiβig', 'vierzig', 'fünfzig', 'sechzig', 'siebzig', 'achtzig', 'neunzig'];

  num = ((num === undefined) ? '0' : num.toString());
  if ((num).length > 6) return 'overflow';
  const breakdown = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d)(\d{2})$/);
  if (!breakdown) return;
  if (language === LANGUAGE_EN) {
    return buildInWordsEN(ones, tens, breakdown);
  } else {
    return buildInWordsDE(onesDE, tensDE, breakdown);
  }
  
}

const buildInWordsEN = (ones, tens, breakdown) => {
  let str = '';
  let connector;
  connector = (str !== '') ? 'and ' : '';
  str      += (parseInt(breakdown[3]) !== 0) ? (ones[Number(breakdown[3])] || tens[breakdown[3][0]] + ' ' + ones[breakdown[3][1]]) + ' thousand ' : '';
  str      += (parseInt(breakdown[4]) !== 0) ? (ones[Number(breakdown[4])] || tens[breakdown[4][0]] + ' ' + ones[breakdown[4][1]]) + ' hundred ' : '';
  str      += (parseInt(breakdown[5]) !== 0) ? (connector) + (ones[Number(breakdown[5])] || tens[breakdown[5][0]] + ' ' + ones[breakdown[5][1]]) : '';

  return str;
}

const buildInWordsDE = (onesDE, tensDE, breakdown) => {
  let str = '';
  let connector;
  connector         = (onesDE[breakdown[3][1]] !== '') ? 'und' : '';
  str              += (parseInt(breakdown[3]) !== 0) ? (onesDE[Number(breakdown[3])] || onesDE[breakdown[3][1]] + (connector) + tensDE[breakdown[3][0]]) + 'tausend' : '';
  connector         = (onesDE[breakdown[4][1]] !== '') ? 'und' : '';
  str              += (parseInt(breakdown[4]) !== 0) ? (onesDE[Number(breakdown[4])] || onesDE[breakdown[4][1]] + (connector) + tensDE[breakdown[4][0]]) + 'hundert' : '';
  const onesDEValue = (onesDE[Number(breakdown[5])] !== 'ein') ? onesDE[Number(breakdown[5])] : 'eins';
  connector         = (onesDE[breakdown[5][1]] !== '') ? 'und' : '';
  str              += (parseInt(breakdown[5]) !== 0) ? (onesDEValue || onesDE[breakdown[5][1]] + (connector) + tensDE[breakdown[5][0]]) : '';

  return str;
}

export const formatTime = (time, format = DATE_FORMAT) => {
  return moment(time).format(format);
}

export const parseIntegerParam = (param, initialValue) => {
  let value = parseInt(param);

  if (isNaN(value) || value < 1) {
    value = initialValue;
  }

  return value;
}

export const formatLocationsSubAreas = (locationsSubAreas) => {
  if (!locationsSubAreas.length) {
    return locationsSubAreas;
  }

  return locationsSubAreas.map(locationsSubArea => {
    if (locationsSubArea.locationId) {
      return locationsSubArea;
    }

    const { locations, subAreas, areaId, level, name } = locationsSubArea;

    const newLocationsSubArea = locations.concat(subAreas);
    newLocationsSubArea.sort((a, b) => a.name.localeCompare(b.name));

    const data = formatLocationsSubAreas(newLocationsSubArea);

    return {
      areaId: areaId,
      level : level,
      name  : name,
      data  : data
    }
  });
}

export const formatEventLocationsSubAreas = (locationsSubAreas) => {
  return locationsSubAreas.map(locationsSubArea => {
    if (locationsSubArea.locationId) {
      return {
        id: locationsSubArea.locationId,
        label: locationsSubArea.name,
        children: []
      };
    }

    const { data, areaId, name } = locationsSubArea;

    const children = formatEventLocationsSubAreas(data);

    return {
      id: areaId,
      label  : name,
      children  : children
    }
  });
}

export const nestedLocations = (list) => {
  list.forEach(item => {
    Object.assign(item, {selected: false});
    if (item.data?.length > 0) {
      nestedLocations(item.data);
    }
  });
};

export const nestedEventLocations = (list) => {
  list.forEach(item => {
    Object.assign(item, {selected: false});
    if (item.children?.length > 0) {
      nestedEventLocations(item.children);
    }
  });
};

export const getInitialLocations = (selectedLocations) => {
  return selectedLocations.map(location => {
    return {
      id: location.locationId,
      label: location.name,
    }
  });
}

export const getUserByUserRole = (users) => {
  const formattedUsers = users.map(user => {
    return {
      id          : user.userId,
      name        : user.lastName + ', ' + user.firstName,
      description : user.username
    }
  });

  return formattedUsers;
}

export const createSelectItem = (id, name) => {
  return {
    id,
    name
  }
}

export const updateURLParams = (params) => {
  const { keywordParam, entityParam = '', pageParam = '', sizeParam = '', sortParam = '', history, pathname } = params;

  const location = {
    pathname: pathname,
    search  : `?${entityParam}${pageParam}${sizeParam}${sortParam}${keywordParam}`
  };

  history.replace(location);
}

/////////////////////////////
// convertToUTC()
/////////////////////////////
export const convertToUTC = (date) => {
  return moment.utc(date);
}

/**
 * Parses a date string without changing the timezone.
 * Supports both UTC (Z) and timezone offset (e.g., +08:00).
 * 
 * @param {string} date - The date string to be parsed.
 * @returns {Object} - The moment object with the parsed date.
 */
export const convertToFixedDate = (date) => {
  return moment.parseZone(date);
};

export const generateURLQueryParam = (objParams) => {
  // Filter out null or empty parameters
  const filteredParams = Object.entries(objParams)
    .filter(([_, value]) => value != null);

  // If no valid parameters, return the empty string
  if (filteredParams.length === 0) {
    return '';
  }

  // Create query string from filtered parameters
  const queryString = filteredParams
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  return `?${queryString}`;
};

export const parseParams = (param) => {
  if (typeof param === 'string') {
    return param.trim() ? param.split(',') : [];
  }
  return param;
};

export const parseObjectParams = (param) => {
  const paramList = parseParams(param);
  return paramList.map(item => {
    const newItem = item?.split(':');
    return {
      id: newItem[0],
      name: newItem[1]
    }
  })
}

export const parseDateParams = (param) => {
  if (param) {
    return moment(param).format(DATE_FORMAT_YYYY_MM_DD);
  }
  return param;
};

/**
 * Formats a date based on the given format key or defaults to a context-based format.
 * @param date - The date to format. Can be a Date object, string, or moment object.
 * @param formatKey - Optional. Specifies the format to use, must be a key from `DATETIME_FORMAT` constant.
 * @returns A string representing the formatted date.
 * 
 * @example
 * const date = moment();
 * const formattedDate1 = formatDate(date, DATETIME_FORMAT.EUROPEAN_DATE_TIME);
 * console.log(formattedDate1); // Output: "30.07.2024 15:30:00"
 * 
 * const formattedDate2 = formatDate(date);
 * console.log(formattedDate2); // Output: "15:30:00, Jul 30" (assuming the current year is 2024)
 */
export const formatDate = (date, formatKey) => {
  const now = moment();
  const utcFormattedDate = convertToUTC(date);
  
  // Determine format based on the provided formatKey or current date context
  const format = formatKey || getDefaultFormat(now, utcFormattedDate);
  return utcFormattedDate.format(format);
};

// Helper function to determine default format based on date comparison
const getDefaultFormat = (now, date) => {
  if (now.isSame(date, DAY)) {
    return DATETIME_FORMAT.TIME_24H;
  } else if (now.isSame(date, YEAR)) {
    return DATETIME_FORMAT.TIME_WITH_MONTH_DAY;
  } else {
    return DATETIME_FORMAT.TIME_WITH_DATE;
  }
};

// "OU=AADDC Users,DC=aadds,DC=dev,DC=portal,DC=sesamsec,DC=com"
/**
 * Format the Microsoft Entra Domain Services name to be MS Entra ID Users DN
 * @param name - The Microsoft Entra Domain Services name
 * @returns A string representing the Full Users DN.
 * 
 * @example
 * const name = aadds.portal.azure.com;
 * const usersDN = formatMSEntraDomainServicesName(name);
 * console.log(usersDN); // Output: "OU=AADDC Users,DC=aadds,DC=portal,DC=azure,DC=com"
 */
export const formatMSEntraDomainServicesName = (name) => {
  const organizationalUnit = `${LDIF.ORGANIZATIONAL_UNIT}=${AADDC_USERS}`;

  if (!name) {
    return `${organizationalUnit}`;
  }  

  const domainComponent = name?.split('.').map(part => `${LDIF.DOMAIN_COMPONENT}=${part}`).join(',');

  return `${organizationalUnit},${domainComponent}`;
};

export const getIndefiniteArticle = (word) => {

  const lowerCaseWord = word.toLowerCase();

  const vowels = ['a', 'e', 'i', 'o', 'u'];

  if (vowels.includes(lowerCaseWord[0])) {
    return 'events-page-side-panel.subTypeArticleAnDescriptionButton';
  }

  return 'events-page-side-panel.subTypeArticleADescriptionButton';
}

/**
 * Get the image path of the controller based on the parameter provided
 * 
 * @param {string} deviceType - The device type of the controller.
 * @param {string} housing - The housing of the controller
 * @returns {Object} - The image path of the controller
 */
export const getControllerImage = (deviceType, housing) => {
  if (deviceType === CONTROLLER_DEVICE_TYPE.SECPASS) {
    return images.CONTROLLERS.SECPASS;
  } else if (housing === CONTROLLER_HOUSING.NINETEENINCHRACK) {
    return images.CONTROLLERS.XPASS_PLUS;
  } else {
    return images.CONTROLLERS.X2CPASS;
  }
}