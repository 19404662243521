import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import { MeetingRoom, Wifi } from '@material-ui/icons/';
import { RecentActors } from '@mui/icons-material';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import DetailsChip from '../../components/details-chip';
import { createColumn } from '../../components/enhanced-table';
import EnhancedTitle from '../../components/enhanced-title';
import EntityEvents from '../../components/entity-events';
import { getAccessPointById } from '../../service/accessPointsApi';
import { ACCESS_POINT_MODULE, ACCESS_POINTS_MODULE, ACTION_VIEW, API_REQUEST_ERROR_MESSAGE, CONTROLLERS, CONTROLLERS_MODULE, DATE_FORMAT, PROFILES, READ_MODE, USERS_MODULE } from '../../utility/constants';
import { createAccessPointEventData } from '../../utility/event';
import LoadingState from './access-point-skeleton';
import useStyles from './styles';

const accessPointEventColumn = [
  createColumn('id', 'ID', false, 'numeric', true),
  createColumn('time', 'events-page.timeColumn', true, 'component', false, true, true),
  createColumn('event', 'events-page.eventColumn', true, 'component', false, false, true),
  createColumn('controller', 'events-page.controllerColumn', true, 'string', false, false, false, CONTROLLERS_MODULE),
  createColumn('reader', 'events-page.readerColumn', true, 'string', false, false, false, ACCESS_POINTS_MODULE),
  createColumn('user', 'events-page.userColumn', true, 'string', false, false, false, USERS_MODULE),
  createColumn('location', 'events-page.locationColumn', true, 'string'),
];

const Content = (props) => {
  const classes = useStyles();
  const { t }   = useTranslation();
  const { name, description, location, entries, profiles, controllerId } = props;

  return(
    <Paper className={classes.paper} elevation={3}>
      <Grid container>
        <Grid item md={7} lg={4} xl={3} sm={7} xs={12} className={classes.detailsContainer}>
          <MeetingRoom className={classes.detailIcon}/>
          <Box >
            <Typography className={classes.detailTitle}>{t('accesspoint-page.detailTitle')}</Typography>
            <Box className={classes.detailsField}>
              <span className={classes.detailText}><Trans i18nKey={'accesspoint-page.nameLabel'} values={{ name }} /></span>
              <span className={classes.detailText}><Trans i18nKey={'accesspoint-page.locationLabel'} values={{ location }} /></span>
              <span className={classes.detailText}><Trans i18nKey={'accesspoint-page.descriptionLabel'} values={{ description }} /></span>
            </Box>
          </Box>
        </Grid>
        <Grid item md={5} lg={8} xl={9} sm={5} xs={12}>
          <Grid container spacing={3}>
            <Grid item md={12} lg={5} xl={4} sm={12} xs={12}>
              <Typography className={classes.detailTitle}>{t('accesspoint-page.readersLabel')}</Typography>
              {
                entries.length > 0 ?
                  <Box className={classes.chipContainer}>
                    {
                      entries.map((reader, index) => 
                        <DetailsChip
                          key={index}
                          icon={<Wifi fontSize='small'/>}
                          id={`accesspoint-reader-chip${index}`}
                          title={reader?.name}
                          description={reader.description}
                          onClick={() => window.open(`/${CONTROLLERS}/${ACTION_VIEW}/${controllerId}`, '_blank')}
                        />
                      )
                    }
                  </Box>
                :
                  <span className={classes.noAttachedEntityNote}>
                    <Trans i18nKey={'accesspoint-page.noReaderAssigned'} values={{entity: 'role'}} />
                  </span>
              }
            </Grid>
            <Grid item md={12} lg={7} xl={8} sm={12} xs={12}>
              <Typography className={classes.detailTitle}>{t('accesspoint-page.profilesLabel')}</Typography>
              {
                profiles.length > 0 ?
                  <Box className={classes.chipContainer}>
                    {
                      profiles.map((profile, index) => 
                        <DetailsChip
                          key={index}
                          icon={<RecentActors fontSize='small'/>}
                          id={`accesspoint-profile-chip${index}`}
                          title={profile?.name}
                          description={profile.description}
                          onClick={() => window.open(`/${PROFILES}/${ACTION_VIEW}/${profile.id}`, '_blank')}
                        />
                      )
                    }
                  </Box>
                :
                  <span className={classes.noAttachedEntityNote}>
                    <Trans i18nKey={'accesspoint-page.noProfileAssigned'} values={{entity: 'role'}} />
                  </span>
              }
            </Grid>
            
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

const AccessPoint = (props) => {
  const { showToaster, match, handlePermissions, newEvent, setNewEvent  } = props;

  const { id }    = match.params;
  const classes   = useStyles();
  const { t }     = useTranslation();
  const [isLoading, setIsLoading]                 = useState(false);
  const [controllerName, setControllerName]       = useState('');
  const [controllerId, setControllerId]           = useState('');
  const [name, setName]                           = useState('');
  const [description, setDescription]             = useState('');
  const [profiles, setProfiles]                   = useState([]);
  const [entries, setEntries]                     = useState([]);
  const [location, setLocation]                   = useState('');

  const eventParams = useMemo(() => {
    return { 
      accessPoints: name
    }
  }, [name]) 

  const getAccessPoint = useCallback(async () => {
    setIsLoading(true);

    if (name) {
      return;
    }

    try {
      const response    = await getAccessPointById(id);
      const accessPoint = response;
      const { name, description, profiles, controllerId, controller, readers, location } = accessPoint;
      
      setName(name);
      setDescription(description);
      setControllerId(controllerId);
      setControllerName(controller.name);
      setLocation(location.name)
      
      if (profiles != null) {
        setProfiles(profiles.map(profile => {
          return {
            id          : profile.profileId,
            name        : profile.name,
            description : `${moment(profile.validFrom).format(DATE_FORMAT)} - ${moment(profile.validUntil).format(DATE_FORMAT)}`,
          }
        }));
      }

      if (readers != null) {
        setEntries(accessPoint.readers.map(reader => {
          return {
            name  : reader.name,
            description: t(READ_MODE[reader.readMode]) ?? ''
          }
        }));
      }
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  }, [t, showToaster, id, name]);

  useEffect(() => {
    if (id) {
      getAccessPoint();
    }
  }, [id, getAccessPoint]);

  const titleProps = {
    title       : name,
    subtitle    : name,
    actionButton: [<></>], 
    isLoading
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Box className={classes.details}>
        <Box className={classes.contentHeader}>
          <EnhancedTitle {...titleProps} />
        </Box>
        {
          isLoading ?
            <LoadingState disabled={true} />
          :
            <Content
              id={id}
              controllerId={controllerId}
              name={`${controllerName}:${name}`}
              description={description}
              location={location}
              profiles={profiles}
              entries={entries}
              isLoading={isLoading}
              disabled={true}
              handlePermissions={handlePermissions}
            />
        }
        {
          name ?
            <EntityEvents
              title={t('accesspoint-page.eventLabel')}
              module={ACCESS_POINT_MODULE}
              params={eventParams}
              columns={accessPointEventColumn}
              format={createAccessPointEventData}
              showToaster={showToaster}
              handlePermissions={handlePermissions}
              newEvent={newEvent}
              setNewEvent={setNewEvent}
            />
          :
          <></>
        }
      </Box>
    </Container>
  );
}

export default AccessPoint;