import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  actionContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    '& .MuiBadge-badge': {
      backgroundColor: colors.PRIMARY_LIGHT,
      color: colors.PRIMARY_TEXT
    }
  },
  popOver: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  overFlowActionButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  clearButtonContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  divider: {
    height: 28,
    margin: 6,
  },
  submitButton: {
    height: 'fit-content',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  actionButtonContainer: {
    justifyContent: 'flex-end',
  },
  visuallyHidden: {
    visibility: 'hidden'
  },
  hiddenChip: {
    visibility: 'hidden !important'
  }
}));