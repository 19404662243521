import { Box, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, List, ListItem, Popover, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, ArrowLeft, ArrowRight } from '@material-ui/icons';
import React, { forwardRef, useCallback, useRef, useState } from 'react';
import { getAllEventFilterChildren } from '../../../utility/event';
import SearchBar from '../../search-bar';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

const DropdownItem = forwardRef((props, ref) => {
  const { item, selectedValues = [], handleSelect, setCurrentMenu, classes, list } = props;

  const allChildren = getAllEventFilterChildren(item.children);
  const isAllChildrenSelected = allChildren?.length && 
    Array.isArray(selectedValues) && allChildren.every(child => selectedValues.some(item => item.id === child.id));
  const isSomeChildrenSelected = allChildren?.length && 
    Array.isArray(selectedValues) && allChildren.some(child => selectedValues.some(item => item.id === child.id));
  const isChecked = (Array.isArray(selectedValues) && 
    Boolean(selectedValues.find(child => child.id === item.id))) || isAllChildrenSelected;

  const handleClick = (event) => {
    event.stopPropagation();
    handleSelect(item, item.children);
  };

  const handleNestedChildren = (event) => {
    event.stopPropagation(); 
    const currentContent = {
      parent: list, 
      items: item.children, 
      name: item.label,
    }
    setCurrentMenu(currentContent); 
  }

  return (
    <ListItem innerRef={ref} button onClick={handleClick} className={classes.listItem}>
      <FormControlLabel
        control={
          <Checkbox 
            checked={isChecked} 
            color='primary'
            indeterminate={isSomeChildrenSelected && !isAllChildrenSelected}
          />
        }
        label={item.label}
      />
      {item.children?.length > 0 && (
        <IconButton size="small" onClick={handleNestedChildren}>
          <ArrowRight fontSize="small" />
        </IconButton>
      )}
    </ListItem>
  );
});

const Dropdown = (props) => {
  const { label, list, handleSubmit, handleSearch, handleList, handleClose, handleScroll, backLabel, handleSelect, selectedValues = [], searchPlaceholder, isLoading, hasMore } = props;
  
  const { t } = useTranslation();
  
  const [anchorEl, setAnchorEl] = useState(null);
  const containerRef = useRef(null);
  const observer = useRef();

  const spanRef = useRef();
  const classes = useStyles();
  const [query, setQuery] = useState('');

  const handleClick = () => setAnchorEl(spanRef.current);

  const onClose = () => {
    setAnchorEl(null);
    handleClose();
  };

  const onConfirm = () => {
    handleSubmit();
    onClose();
  };

  const handleClearQuery = () => {
    setQuery('');
    handleSearch('');
  }

  const handleQuery = (value) => {
    setQuery(value);
    handleSearch(value);
  }

  const lastItemElement = useCallback((node) => {
    if (isLoading) {
      return;
    };

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        handleScroll(); 
      }
    });
  
    if (node) {
      observer.current.observe(node);
    }
  }, [isLoading, hasMore, handleScroll]);

  return (
    <div className={classes.dropDowncontainer}>
      <Typography component="span" ref={spanRef} className={classes.label}> 
        {label}
      </Typography>
      <IconButton onClick={handleClick} className={classes.entityIcon}>
        {anchorEl ? <ArrowDropUp fontSize='small'/>  : <ArrowDropDown fontSize='small'/>}
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div className={classes.container}>
          {(list.items.length > 8 || query) && (
            <SearchBar query={query} handleSearch={handleQuery} placeholder={searchPlaceholder} handleClearSearch={handleClearQuery}/>
          )}
          <div ref={containerRef} className={classes.content}>
            <List>
              {list.parent && (
                <ListItem button onClick={() => handleList(list.parent)} className={classes.previous}>
                  <IconButton size="small">
                    <ArrowLeft fontSize="small" />
                  </IconButton>
                  <Typography>{list.parent.isMain ? backLabel: t('events-page-filter.goBackLocations', {value: list.name})}</Typography>
                </ListItem>
              )}
              {list.items.map((item, index) => {
                const isLastIndex = index === list.items.length - 1;
                return (
                  <DropdownItem 
                    key={index} 
                    item={item} 
                    selectedValues={selectedValues} 
                    handleSelect={handleSelect} 
                    setCurrentMenu={handleList} 
                    classes={classes}
                    list={list}
                    ref={isLastIndex ? lastItemElement: null}
                  />
              )})}
            </List>
          </div>
          <Box className={classes.loadField}>{isLoading && <CircularProgress size={24} className={classes.loading} />}</Box>
          {list?.isMain && (
            <div className={classes.buttonContainer}>
              <Button color="primary" onClick={onClose}>Cancel</Button>
              <Button color="primary" onClick={onConfirm}>OK</Button>
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
}

export default Dropdown;