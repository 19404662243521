import clsx from 'clsx';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventsFilterContext } from '../../../context/eventsFIlterContext';
import { ADD_FILTER } from '../../../reducer/eventsFilterReducer';
import { EVENT_FILTER_COMPONENT_ORDER, EVENT_FILTER_COMPONENTS } from '../../../utility/constants';
import { filterChildrenByLabels, formatEventTypesFilter, getAllEventFilterChildren, getEventInitialLabel, getInitialEventFilterValues } from '../../../utility/event';
import Chip from '../chip';
import Dropdown from '../drop-down';
import useStyles from './styles';

const EventTypes = (props) => {
  const { overflowItems, chipRefs } = props;
  
  const { t } = useTranslation();
  
  const { state: stateFilter, dispatch } = useContext(EventsFilterContext);
  
  // Constants and utility variables
  const name = EVENT_FILTER_COMPONENTS.EVENT_TYPES;
  const componentOrder = EVENT_FILTER_COMPONENT_ORDER.indexOf(name);
  
  const { subTypes: subTypesState = [] } = stateFilter;

  const eventTypeData = useMemo(() => formatEventTypesFilter(), []);
  const initialEventTypeValues = getInitialEventFilterValues(subTypesState);
  const initialEventTypes = filterChildrenByLabels(eventTypeData, initialEventTypeValues);
  
  const defaultLabel = t(`events-page-filter.${name}`);

  const initialLabel = getEventInitialLabel(initialEventTypes, defaultLabel, t('events-page-filter.multipleEventTypesLabel', { count: initialEventTypes?.length }));

  const initialList = { parent: null, items: eventTypeData, isMain: true };

  const classes = useStyles();
  const [status, setStatus] = useState(initialEventTypes.length);
  const [selectedValues, setSelectedValues] = useState(initialEventTypes);
  const [list, setList] = useState(initialList);
  const [tempList, setTempList] = useState(initialList);
  const [label, setLabel] = useState(initialLabel);
  
  const handleDelete = () => {
    setSelectedValues([]);
    setLabel(defaultLabel);
    dispatch({ type: ADD_FILTER, payload: {...stateFilter, subTypes: [], types: [] } })

    setStatus(false);
  };

  const handleSelect = (value, children = []) => {
    let updatedSelections = [...selectedValues];
    const allChildValues = getAllEventFilterChildren(children);
    const hasChildren = children.length > 0;
    const areAllChildrenSelected = allChildValues.length && allChildValues.every(child => selectedValues.find(item => child.id === item.id));
    const isValueSelected = updatedSelections.find(item => value.id === item.id)
    
    if (hasChildren && areAllChildrenSelected) {
        updatedSelections = updatedSelections.filter(item => !allChildValues.find(child => child.id === item.id));
    } else if (isValueSelected && !hasChildren) {
        updatedSelections = updatedSelections.filter(item => item.id !== value.id);
    } else if (!isValueSelected && !hasChildren) {
        updatedSelections.push(value);
    } else {
      // Merge selections and child values
      updatedSelections = [...selectedValues, ...allChildValues];

      // Remove duplicates, using 'id' as the key
      updatedSelections = Array.from(new Map(updatedSelections.map(item => [item.id, item])).values());
    }
    
    setSelectedValues(updatedSelections);
  };  

  const handleSearch = (keyword) => {
    if (!keyword) {
      setList(tempList);
      return;
    }

    const filteredItems = list.items.filter(item => 
      item.label.toLowerCase().includes(keyword.toLowerCase())
    );

    setList(prevState => ({
      ...prevState,
      items: filteredItems
    }));
  };

  const handleList = (item) => {
    setList(item);
    setTempList(item);
  }

  const handleClose = () => {
    const resetEventTypes = getInitialEventFilterValues(subTypesState);
    const newLabel = getEventInitialLabel(resetEventTypes, defaultLabel, t('events-page-filter.multipleEventTypesLabel', { count: resetEventTypes?.length }));
    
    setSelectedValues(resetEventTypes);
    setLabel(newLabel);
    setStatus(!!resetEventTypes.length);
    setList(initialList);
    setTempList(initialList);
  }

  const isArrayLocationsEqual = (selectedLocations, prevLocations) => {
    if (selectedLocations.length !== prevLocations.length) {
      return false;
    }

    return selectedLocations.every((location, index) => location.id === prevLocations[index].id);
  };

  const handleSubmit = () => {
    const hasChanged = !isArrayLocationsEqual(selectedValues, initialEventTypes);
    if (!hasChanged) {
      return;
    }
    const subTypes = selectedValues.map(value => value.label);
    const types = getEventTypeParentLabels(eventTypeData, subTypes);

    dispatch({ type: ADD_FILTER, payload: {...stateFilter, subTypes, types } })
    
    let newLabel = defaultLabel;
    if (selectedValues?.length > 1) {
      newLabel = t('events-page-filter.multipleEventTypesLabel', { count: selectedValues?.length });
    } else if (selectedValues.length === 1) {
      newLabel = selectedValues[0]?.label; 
    }

    setLabel(newLabel);
    setStatus(hasChanged);
  };

  const getEventTypeParentLabels = (list, labels) => {
    const labelArray = labels.map(label => label.trim());
    const parentLabels = [];
    list.forEach(item => {
      const matchingChildren = item.children.filter(child => labelArray.includes(child.label));
      if (matchingChildren.length > 0) {
        parentLabels.push(item.label);
      }
    });
  
    return parentLabels;
  };

  useEffect(() => {
    const initialEventTypes = filterChildrenByLabels(eventTypeData, subTypesState);
    setSelectedValues(initialEventTypes);
    let initialLabel = getEventInitialLabel(subTypesState, defaultLabel, t('events-page-filter.multipleEventTypesLabel', { count: subTypesState?.length }));
    setLabel(initialLabel);
    setStatus(subTypesState.length);
  }, [defaultLabel, subTypesState, eventTypeData, t]);

  return (
    <Chip
      handleDelete={handleDelete}
      showCloseIcon={status}
      containerStyle={clsx(
        !status ? classes.inactiveChip : '',
        overflowItems?.includes(componentOrder) ? classes.hiddenChip : '',
        !chipRefs && classes.overflow
      )}
      status={selectedValues.length}
      ref={(ref) => {
        if (ref && chipRefs) chipRefs.current[componentOrder] = ref;
      }}
    >
      <Dropdown
        label={label}
        list={list}
        handleSubmit={handleSubmit}
        handleSearch={handleSearch}
        handleScroll={() => {}}
        backLabel={t('events-page-filter.goBacktoMainEventTypes')}
        handleSelect={handleSelect}
        selectedValues={selectedValues}
        handleList={handleList}
        handleClose={handleClose}
      />
    </Chip>
  );
};

export default EventTypes;
