import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import cookies from 'js-cookie';
import moment from 'moment';
import React, { forwardRef, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { FILTER_EVENT_FORMAT, LANGUAGE_EN } from '../../../utility/constants';
import useStyles from './styles';

const EventFilterCalendar = forwardRef((props, ref) => {
  const today = new Date();
  const classes = useStyles();
  const language = cookies.get('i18next') || LANGUAGE_EN;
  const { startDate, endDate, onEventDateRangeChange } = props;
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleChange = (value) => {
    onEventDateRangeChange(moment(value[0]).format(FILTER_EVENT_FORMAT), moment(value[1]).format(FILTER_EVENT_FORMAT))
  }

  const handleInvalidChange = () => {
    onEventDateRangeChange(startDate, endDate);
  }

  const handleCalendarOpen = () => {
    setIsCalendarOpen(true);
  }

  const handleCalendarClose = () => {
    setIsCalendarOpen(false);
  }

  const handleFocus = (e) => {
    const { target } = e;

    if (target) {
      target.readOnly = true;
      target.blur();
    }
  };

  return (
    <div className={classes.dateContainer} ref={ref}>
      <DateRangePicker 
        calendarIcon={isCalendarOpen ? <ArrowDropUp color='secondary' fontSize='small'/> : <ArrowDropDown color='secondary' fontSize='small'/>}
        className={classes.main}
        clearIcon={null}
        format="dd/MM/y"
        locale={language}
        maxDate={today}
        onChange={handleChange} 
        onInvalidChange={handleInvalidChange}
        rangeDivider=" - "
        value={[startDate, endDate]} 
        onCalendarOpen={handleCalendarOpen}
        onCalendarClose={handleCalendarClose}
        onFocus={handleFocus}
        />
    </div>
  )
})

export default EventFilterCalendar;