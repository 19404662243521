import { Box, Chip as MuiChip, Divider } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from 'clsx';
import React, { forwardRef } from "react";
import useStyles from "./styles";

const Chip = forwardRef(({ handleDelete, children, icon, showCloseIcon = true, containerStyle , tabIndex, status }, ref) => {
  const classes = useStyles();

  return (
    <MuiChip
      className={clsx(classes.chip, containerStyle)}
      innerRef={ref}
      id={tabIndex}
      deleteIcon={
        showCloseIcon ? (
          <Box className={classes.deleteBox} onClick={handleDelete}>
            <Divider className={classes.divider} orientation="vertical" />
            <Close />
          </Box>
        ) : (
          <></>
        )
      }
      onDelete={handleDelete}
      label={<Box className={classes.label}>{children}</Box>}
      icon={icon ? icon : <></>}
      data-status={status}
    />
  );
});

export default Chip;
