import moment from "moment";
import { ALL, DESCENDING, FILTER_EVENT_FORMAT, TIME } from "../utility/constants";

export const ADD_FILTER        = 'ADD_FILTER';
export const UPDATE_EVENTS     = 'UPDATE_EVENTS';
export const CLEAR_FILTER      = 'CLEAR_FILTER';
export const ADD_ROWS_PER_PAGE = 'ADD_ROWS_PER_PAGE';
export const UPDATE_LIST_TYPE  = 'UPDATE_LIST_TYPE'; 

export const initialState = {
  listType        : ALL,
  types           : [],
  subTypes        : [],
  from            : moment(new Date()).subtract(6, "days").format(FILTER_EVENT_FORMAT),
  deviceIds       : [],
  until           : moment(new Date()).format(FILTER_EVENT_FORMAT),
  accessPoints     : [],
  credentialNumbers: [],
  locationIds     : [],
  keyword         : '',
  size            : 100,
  page            : 1,
  sort            : `${TIME},${DESCENDING}`,
  events          : []
}

export const EventsFilterReducer = (state, action) => {
  switch (action.type) {
    case ADD_FILTER:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_EVENTS: 
      return {
        ...state,
        events: action.payload
      }
    case ADD_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage : action.payload
      }
    case CLEAR_FILTER: {
      return initialState
    }
    case UPDATE_LIST_TYPE: {
      return {
        ...state,
        listType : action.payload
      }
    }
    default:
      return state
  }
}