import {
  Backdrop,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Modal,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import clsx from "clsx";
import { Form, Formik } from "formik";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectItems from "../../../components/select-items";
import { AuthContext } from "../../../context/authContext";
import { UPDATE_ACCOUNT } from "../../../reducer/authReducer";
import {
  AssignAdministratorSystemRoles,
  CreateAdministrator,
  DeleteAdministratorSystemRoles,
  GetAdministratorById,
  GetSystemRolesForAdministrator,
  GetWebClientID,
  UpdateAdministrator,
  createOrDeleteAuthorizations,
} from "../../../service/administratorsApi";
import {
  ACTION_CREATE,
  ACTION_EXIT,
  ACTION_UPDATE,
  ACTION_VIEW,
  API_REQUEST_ERROR_MESSAGE,
  CREATE,
  GET,
  LOCATIONS_MODULE,
  MAX_CHARACTER_LIMIT,
  STATUS_ACTIVE,
  SYSTEM_ADMIN,
  SYSTEM_ROLES_MODULE
} from "../../../utility/constants";
import { isArrayEqual } from "../../../utility/helper";
import { getLocationsByAdministratorId } from "../../../utility/location";
import { createAdministratorSchema, createSystemAdminSchema, updateAdministratorSchema, updateSystemAdminSchema } from "../../../validation/schema";
import { CreateUpdateAdministratorsModalSkeleton, ViewAdministratorsModalSkeleton } from "../administrators-skeleton";
import useStyles from "./styles";

const Content = (props) => {
  const {
    actionType,
    handleSubmit,
    initialValues,
    disabled,
    handleCancel,
    setFirstName,
    setLastName,
    setEmail,
    role,
    showToaster,
    handleSelectedRoles,
    handlePermissions,
    selectedLocations,
    handleSelectedLocations
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const isSystemAdmin = role.map(role => role.name).includes(SYSTEM_ADMIN)
  const showLocations = role.length > 0 && !isSystemAdmin;

  const getButtonLabel = () => {
    return actionType === ACTION_UPDATE ? `${t("update")}` : `${t("create")}`;
  };

  const handleChange = (setter, formik) => {
    return { setter, formik };
  };

  const getAdministratorSchema = () => {
    if (isSystemAdmin) {
      if (actionType === ACTION_UPDATE) {
        return updateSystemAdminSchema;
      } else {
        return createSystemAdminSchema;
      }  
    } else {
      if (actionType === ACTION_UPDATE) {
        return updateAdministratorSchema;
      } else {
        return createAdministratorSchema;
      }  
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={getAdministratorSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form>
          <Grid container spacing={2} className={classes.paper}>
            <Grid item xs={6} md={6} className={classes.grid}>
              <TextField
                inputProps={{
                  readOnly: disabled,
                  maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                }}
                disabled={disabled}
                id="AdministratorsModalFirstName"
                label={`${t("firstName")}*`}
                name="firstName"
                variant="outlined"
                fullWidth
                value={formik.values.firstName}
                onChange={(e) =>
                  handleChange(
                    setFirstName(e.target.value),
                    formik.handleChange(e)
                  )
                }
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={
                  t(formik.touched.firstName) && t(formik.errors.firstName)
                }
              />
            </Grid>
            <Grid item xs={6} md={6} className={classes.grid}>
              <TextField
                inputProps={{
                  readOnly: disabled,
                  maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                }}
                disabled={disabled}
                id="AdministratorsModalLastName"
                label={`${t("lastName")}*`}
                name="lastName"
                variant="outlined"
                fullWidth
                value={formik.values.lastName}
                onChange={(e) =>
                  handleChange(
                    setLastName(e.target.value),
                    formik.handleChange(e)
                  )
                }
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={
                  t(formik.touched.lastName) && t(formik.errors.lastName)
                }
              />
            </Grid>
            <Grid item xs={6} md={6} className={classes.grid}>
              <TextField
                inputProps={{
                  readOnly: disabled,
                }}
                disabled={actionType !== CREATE}
                id="AdministratorsModalEmail"
                label={`${t("emailAddress")}*`}
                name="email"
                variant="outlined"
                fullWidth
                value={formik.values.email}
                onChange={(e) =>
                  handleChange(setEmail(e.target.value), formik.handleChange(e))
                }
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={t(formik.touched.email) && t(formik.errors.email)}
              />
            </Grid>
            <Grid item xs={6} md={6} className={classes.grid}>
              <SelectItems
                id="AdministratorsModalAdministratorSelectRole"
                name={SYSTEM_ROLES_MODULE}
                module={SYSTEM_ROLES_MODULE}
                onChange={handleSelectedRoles}
                selectedItems={role}
                showToaster={showToaster}
                handlePermissions={handlePermissions}
                disabled={disabled}
                required={true}
                helperText={formik.touched.role && t(formik.errors.role)}
                isValid={formik.touched.role && Boolean(formik.errors.role)}
              />
            </Grid>
            {
              handlePermissions(LOCATIONS_MODULE, GET) && showLocations &&
              <Grid item xs={12} md={12} lg={6}>
                <SelectItems
                  id="administratorsModalLocation"
                  helperText={formik.touched.locations && t(formik.errors.locations)}
                  isValid={formik.touched.locations && Boolean(formik.errors.locations)}
                  name="Locations"
                  onChange={handleSelectedLocations}
                  disabled={disabled}
                  selectedItems={selectedLocations}
                  showToaster={showToaster}
                  required={true}
                  handlePermissions={handlePermissions}
                  truncate={true}
                />
              </Grid>
            }
          </Grid>
          <Grid className={clsx(disabled ? "hidden" : classes.action)}>
            <Grid item xs={12}>
              <Button
                id="AdministratorsModalCancelButton"
                onClick={handleCancel}
                variant="outlined"
                color="primary"
              >
                {t("cancel")}
              </Button>
              <Button
                id="AdministratorsModalSubmitButton"
                type="submit"
                variant="contained"
                color="primary"
              >
                {getButtonLabel()}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const AdministratorsModal = (props) => {
  const {
    showToaster,
    showLoading,
    setReload,
    id,
    actionType,
    setActionType,
    administratorModalOpenState,
    setAdministratorModalOpenState,
    withModalChanges,
    setWithModalChanges,
    setShowUnsavedModal,
    setIsShowLoading,
    isShowLoading,
    setUnsavedModalAction,
    handlePermissions,
    setSelectedAdministrator,
    selectedAdministrator,
    handleUpdateClick,
    locations,
    setLocations
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState([]);
  const [status, setStatus] = useState("ACT");
  const [isGettingAdministrator, setIsGettingAdministrator] = useState(false);
  
  const isCreate = actionType === ACTION_CREATE;
  const isUpdate = actionType === ACTION_UPDATE;

  const [prevValues, setPrevValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: [],
    status: STATUS_ACTIVE,
    locations: []
  });

  const initialValues = useMemo(() => {
    return {
      firstName: firstName,
      lastName: lastName,
      email: email,
      role: role,
      status: status,
      locations: locations
    }
  }, [firstName, lastName, email, role, status, locations]);

  const {state, dispatch : dispatchAdministrator} = useContext(AuthContext); 
  const { administrator } = state;

  const getAdministrator = useCallback(async () => {
    setIsGettingAdministrator(true);
    const administratorData = await GetAdministratorById(id);
    const clientId = await GetWebClientID();

    const systemRolesData = await GetSystemRolesForAdministrator(id, clientId);
    const adminLocations = await getLocationsByAdministratorId(id);

    const prevValueData = {
      firstName : administratorData.firstName,
      lastName  : administratorData.lastName,
      email     : administratorData.email,
      company   : administratorData.company,
      role      : systemRolesData,
      status    : administratorData.status,
      locations : adminLocations
    };

    setPrevValues(prevValueData);
    setRole(systemRolesData);
    setFirstName(administratorData.firstName);
    setLastName(administratorData.lastName);
    setEmail(administratorData.email);
    setStatus(administratorData.status);
    setLocations(adminLocations);
  }, [id, setLocations]);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          await getAdministrator();
        } catch {
          showToaster(t("error"), t(API_REQUEST_ERROR_MESSAGE), "error");
        } finally {
          setIsGettingAdministrator(false);
        }
      }
      fetchData();
    }
  }, [actionType, id, getAdministrator, showToaster, t]);

  const handleChanges = useCallback(() => {
    if (
      prevValues?.firstName === initialValues.firstName &&
      prevValues?.lastName === initialValues.lastName &&
      prevValues?.email === initialValues.email &&
      hasChanges(initialValues.role, prevValues?.role) &&
      hasLocationChanges(initialValues.locations, prevValues?.locations)
    ) {
      setWithModalChanges(false);
    } else {
      setWithModalChanges(true);
    }
  }, [
    initialValues,
    prevValues,
    setWithModalChanges,
  ]);

  useEffect(() => {
    handleChanges();
  }, [
    firstName,
    lastName,
    email,
    role,
    status,
    prevValues,
    handleChanges,
  ]);

  useEffect(() => {
    if (actionType === ACTION_CREATE) {
      setFirstName("");
      setLastName("");
      setEmail("");
      setRole([]);
      setStatus("ACT");
    }
  }, [actionType]);

  const getToasterMessage = () => {
    return actionType === ACTION_CREATE
      ? t("hasBeenCreated")
      : t("hasBeenUpdated");
  };

  const updateAdministratorState = (firstName, lastName) => {
    const administrator = {
      ...state.administrator,
      firstName: firstName,
      lastName: lastName
    }

    const newAdministrator = {
      ...state,
      administrator: administrator
    }

    dispatchAdministrator({type: UPDATE_ACCOUNT, payload: newAdministrator});

  }

  const handleSubmit = async (values, formik) => {
    const { setSubmitting, setErrors } = formik;

    if (!withModalChanges) {
      setSubmitting(false);
      setWithModalChanges(false);
      setAdministratorModalOpenState(false);
      return;
    }

    setIsShowLoading(true);

    if (!isCreate) {
      delete values.password;
    }

    try {

      const isSystemAdmin = role.find(role => role.name === SYSTEM_ADMIN) ? true : false;

      if (isCreate) {
        const adminId = await CreateAdministrator(values);
        const locationIds = values.locations.map(location => location.locationId);
        await createOrDeleteAuthorizations(locationIds, adminId, isSystemAdmin);
      } else if (isUpdate && withModalChanges) {
        const administratorObject = {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          enabled: true,
          username: values.email
        }

        await UpdateAdministrator(id, administratorObject);

        if (id === administrator.administratorId) {
          updateAdministratorState(values.firstName, values.lastName);
        }

        const locationIds = values.locations.map(location => location.locationId);
        await createOrDeleteAuthorizations(locationIds, id, isSystemAdmin);

        const hasRoleChanges = !hasChanges(initialValues.role, prevValues.role);

        if (hasRoleChanges) {
          const toAdd = initialValues.role.filter(
            ({ id: id1 }) => !prevValues.role.some(({ id: id2 }) => id2 === id1)
          );
          const toRemove = prevValues.role.filter(
            ({ id: id1 }) =>
              !initialValues.role.some(({ id: id2 }) => id2 === id1)
          );

          if (toAdd.length > 0) {
            await AssignAdministratorSystemRoles(id, toAdd);
          }

          if (toRemove.length > 0) {
            await DeleteAdministratorSystemRoles(id, toRemove);
          }
        }
      }

      setAdministratorModalOpenState(false);
      setWithModalChanges(false);
      setActionType("");
      setReload(true);

      showToaster(
        t("success"),
        `${values.lastName}, ${values.firstName} ${getToasterMessage()}`,
        "success"
      );
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setErrors({
          email: t("emailAlreadyExists"),
        });
      } else {
        showToaster(t("error"), t(API_REQUEST_ERROR_MESSAGE), "error");
      }
    } finally {
      setSubmitting(false);
      setIsShowLoading(false);
    }
  };

  const handleCancel = () => {
    handleChanges();
    if (withModalChanges) {
      setUnsavedModalAction(ACTION_EXIT);
      setShowUnsavedModal(true);
    } else {
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    setAdministratorModalOpenState(false);
    setSelectedAdministrator(false);
    setActionType("");
    setPrevValues({
      firstName: "",
      lastName: "",
      email: "",
      role: [],
      status: STATUS_ACTIVE,
      locations: []
    });
  };

  const handleSelectedRoles = (values) => {
    setRole(values);
  };

  const handleSelectedLocations = (values) => {
    setLocations(values);
  };

  const hasChanges = (current, previous) => {
    const currentIds = current ? current.map((item) => item.id) : [];
    const previousIds = previous ? previous.map((item) => item.id) : [];
    return isArrayEqual(currentIds, previousIds);
  };

  const hasLocationChanges = (current, previous) => {
    const currentIds = current ? current.map((item) => item.locationId) : [];
    const previousIds = previous ? previous.map((item) => item.locationId) : [];
    return isArrayEqual(currentIds, previousIds);
  };

  return (
    <Box>
      {showLoading(isShowLoading)}
      <Modal
        disableEnforceFocus
        disableBackdropClick
        disableEscapeKeyDown
        aria-describedby="controller-dialog-modal"
        aria-labelledby="controller-dialog"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        autoFocus={false}
        className={classes.administratorModal}
        onClose={handleCancel}
        open={administratorModalOpenState}
      >
        <Container className={`${classes.modalContent}`}>
          <Box className={`${classes.modalTitle} ${"bold"} ${classes.form}`}>
            {t(`administrator-page.${actionType}Administrator`)}
          <Box className={actionType === ACTION_VIEW ? classes.closeButton : 'hidden'}>
            <Tooltip title={t('update')}>
              <IconButton
                id={`view-administrator-update-button`}
                aria-label="update"
                onClick={() => handleUpdateClick(selectedAdministrator)}
                className={classes.icon}
              >
                <Edit size="xs"/>
              </IconButton>
            </Tooltip>
            <Tooltip title={t('close')}>
              <IconButton
                disableRipple
                disableFocusRipple
                onClick={handleCloseModal} 
                className={classes.icon}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
          </Box>
          <hr className={classes.hrDivider} />
          {isGettingAdministrator ? (
            actionType === ACTION_VIEW ?
              <ViewAdministratorsModalSkeleton handlePermissions={handlePermissions}/>
            :
              <CreateUpdateAdministratorsModalSkeleton handlePermissions={handlePermissions}/>
          ) : (
            <Content
              id={id}
              disabled={actionType === ACTION_VIEW}
              initialValues={initialValues}
              handleCancel={handleCancel}
              handleSubmit={handleSubmit}
              handleSelectedRoles={handleSelectedRoles}
              showToaster={showToaster}
              actionType={actionType}
              role={role}
              setFirstName={setFirstName}
              setLastName={setLastName}
              setEmail={setEmail}
              setStatus={setStatus}
              showLoading={showLoading}
              handlePermissions={handlePermissions}
              handleSelectedLocations={handleSelectedLocations}
              selectedLocations={locations}
            />
          )}
        </Container>
      </Modal>
    </Box>
  );
};

export default AdministratorsModal;
