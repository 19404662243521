import clsx from 'clsx';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { EventsFilterContext } from '../../../context/eventsFIlterContext';
import { ADD_FILTER } from '../../../reducer/eventsFilterReducer';
import api from '../../../service/api';
import { getCredentialsApi } from '../../../service/credentialApi';
import { EVENT_FILTER_COMPONENT_ORDER, EVENT_FILTER_COMPONENT_PAGINATION, EVENT_FILTER_COMPONENTS } from '../../../utility/constants';
import { getEventInitialLabel, getInitialEventFilterValues } from '../../../utility/event';
import Chip from '../chip';
import Dropdown from '../drop-down';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

const Credentials = (props) => {
  const { overflowItems, chipRefs } = props;

  const { t } = useTranslation();

  const name = EVENT_FILTER_COMPONENTS.CREDENTIALS;
  const componentOrder = EVENT_FILTER_COMPONENT_ORDER.indexOf(name);
  
  const { state: stateFilter, dispatch } = useContext(EventsFilterContext);
  
  const { credentialNumbers: credentialNumbersState = [] } = stateFilter;
  const initialCredentials = getInitialEventFilterValues(credentialNumbersState);

  const defaultLabel = t(`events-page-filter.${name}`);
  const initialLabel = getEventInitialLabel(initialCredentials, defaultLabel, t('events-page-filter.multipleCredentialsLabel', { count: initialCredentials?.length }));
  
  const initialList = { parent: null, items: [], isMain: true };

  const classes = useStyles();
  const [status, setStatus] = useState(initialCredentials.length);
  const [selectedValues, setSelectedValues] = useState(initialCredentials);
  const [list, setList] = useState(initialList);
  const [label, setLabel] = useState(initialLabel);
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const handleDelete = () => {
    setSelectedValues([]);
    setLabel(defaultLabel);
    dispatch({ type: ADD_FILTER, payload: {...stateFilter, credentialNumbers: [] } })
    setStatus(false);
  };

  const handleSelect = (value) => {
    let updatedSelections = [...selectedValues];
    const isValueSelected = updatedSelections.find(item => value.id === item.id)
    
    if (isValueSelected) {
      updatedSelections = updatedSelections.filter(item => item.id !== value.id);
    } else {
      updatedSelections.push(value);
    }
    
    setSelectedValues(updatedSelections);
  };  

  const handleSearch = (value) => {
    setKeyword(value);
    setPage(0);
  };

  const handleList = (item) => {
    setList(item);
  }

  const handleClose = () => {
    const resetCredentials = getInitialEventFilterValues(credentialNumbersState);
    const newLabel = getEventInitialLabel(resetCredentials, defaultLabel, t('events-page-filter.multipleCredentialsLabel', { count: resetCredentials?.length }));
    
    setSelectedValues(resetCredentials);
    setLabel(newLabel);
    setStatus(!!resetCredentials.length);
  }

  const isArrayLocationsEqual = (selectedLocations, prevLocations) => {
    if (selectedLocations.length !== prevLocations.length) {
      return false;
    }

    return selectedLocations.every((location, index) => location.id === prevLocations[index].id);
  };

  const handleSubmit = () => {
    const hasChanged = !isArrayLocationsEqual(selectedValues, initialCredentials);
    if (!hasChanged) {
      return;
    }
    const credentialNumbers = selectedValues.map(value => {
      return {
        id: value.id, 
        label: value.label
      }
    });

    dispatch({ type: ADD_FILTER, payload: {...stateFilter, credentialNumbers } })

    let newLabel = name;
    if (selectedValues?.length > 1) {
      newLabel = t('events-page-filter.multipleCredentialsLabel', { count: selectedValues?.length });
    } else if (selectedValues.length === 1) {
      newLabel = selectedValues[0]?.label; 
    }

    setLabel(newLabel);
    setStatus(hasChanged);
  };

  const getUrl = useCallback(() => {
    return keyword ? api.CREDENTIALS_SEARCH : api.CREDENTIALS
  }, [keyword])

  const getData = useCallback(async() => {
    setIsLoading(true);
    try {
      const parameters = {
        keyword,
        page,
        rowsPerPage: EVENT_FILTER_COMPONENT_PAGINATION.SIZE
      }
      const response = await getCredentialsApi(parameters, getUrl());
      const { _embedded, page: pagination} = response;

      const { number, totalElements, totalPages} = pagination;
      const credentials = _embedded.credentials.map((credential) => {
        const { credentialNumber, credentialId } = credential;
        return {
          id: credentialId,
          label: credentialNumber,
          children: []
        }
      });

      setList(prev => {
        const newList = number === 0 ? credentials : [...prev.items, ...credentials];
        const updatedItems = newList.map((item, index) => {
          return {
            ...item,
            id: index
          }
        });
        return { 
          parent: null, 
          items: updatedItems, 
          isMain: true 
        }
      });
      setHasMore(totalElements > 0 && page < totalPages - 1);

    } catch(e) {
      console.log('Error', e)
    } finally {
      setIsLoading(false);
    }
  }, [keyword, page, getUrl]);

  const handleScroll = () => {
    setPage((prev) => prev + 1);
  }

  useEffect(() => {
    let delayDebounce;

    if (keyword) {
      delayDebounce = setTimeout(() => {
        getData();
      }, 500);
    } else {
      getData();
    }

    return () => {
      delayDebounce && clearTimeout(delayDebounce);
    }
  }, [keyword, page, getData]);

  useEffect(() => {
    const initialLabel = getEventInitialLabel(
      credentialNumbersState, defaultLabel, t('events-page-filter.multipleCredentialsLabel', { count: credentialNumbersState?.length }));
    setSelectedValues(credentialNumbersState);
    setLabel(initialLabel);
    setStatus(credentialNumbersState.length);
  }, [credentialNumbersState, defaultLabel, t]);

  return (
    <Chip
      handleDelete={handleDelete}
      showCloseIcon={status}
      containerStyle={clsx(
        !status ? classes.inactiveChip : classes.activeChip,
        overflowItems?.includes(componentOrder) ? classes.hiddenChip : '',
        !chipRefs && classes.overflow
      )}
      status={selectedValues.length}
      ref={(ref) => {
        if (ref && chipRefs) chipRefs.current[componentOrder] = ref;
      }}
    >
      <Dropdown
        label={label}
        list={list}
        handleSubmit={handleSubmit}
        handleSearch={handleSearch}
        handleScroll={handleScroll}
        handleSelect={handleSelect}
        selectedValues={selectedValues}
        handleList={handleList}
        handleClose={handleClose}
        isLoading={isLoading}
        hasMore={hasMore}
      />
    </Chip>
  );
}

export default Credentials;
