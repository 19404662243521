import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(0),
    '& .MuiTypography-root': {
      color: colors.DARK_GRAY,
      lineHeight: '20px',
      fontSize: theme.spacing(1.75)
    }
  },
  value: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginBottom: theme.spacing(2)
  },
  bullet: {
    display: 'list-item',
    marginLeft: theme.spacing(2),
  },
  warning: {
    marginTop: theme.spacing(2),
  }
}));