import {
  Box,
  IconButton,
  InputBase,
  Paper,
  Typography
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { EventsFilterContext } from '../../../context/eventsFIlterContext';
import { LocationContext } from '../../../context/locationContext';
import useParams from '../../../hooks/useParams';
import { ADD_FILTER, initialState } from '../../../reducer/eventsFilterReducer';
import { ACCESS_POINTS_MODULE, CONTROLLERS_MODULE, CREDENTIALS_MODULE, EVENT_FILTER_COMPONENT_ORDER, EVENT_FILTER_COMPONENT_PAGINATION, EVENT_FILTER_COMPONENTS, EVENT_FILTER_PARAMETERS, EVENTS_MODULE, GET, LOCATIONS_MODULE } from '../../../utility/constants';
import { parseJSONParam } from '../../../utility/event';
import { getInitialLocations } from '../../../utility/helper';
import AccessPoints from '../access-points';
import ActionButtons from '../action-button';
import Chip from '../chip';
import Controllers from '../controllers';
import Credentials from '../credentials';
import EventFilterCalendar from '../event-filter-calendar';
import EventTypes from '../event-types';
import Locations from '../locations';
import useStyles from './styles';

const EVENT_FILTER_ENTITIES = [
  {
    name: LOCATIONS_MODULE,
    component: <Locations />,
    parameter: EVENT_FILTER_PARAMETERS.LOCATIONS
  },
  {
    name: EVENTS_MODULE,
    component: <EventTypes />,
    parameter: EVENT_FILTER_PARAMETERS.SUB_TYPES
  },
  {
    name: CONTROLLERS_MODULE,
    component: <Controllers />,
    parameter: EVENT_FILTER_PARAMETERS.CONTROLLERS
  },
  {
    name: ACCESS_POINTS_MODULE,
    component: <AccessPoints />,
    parameter: EVENT_FILTER_PARAMETERS.ACCESS_POINTS
  },
  {
    name: CREDENTIALS_MODULE,
    component: <Credentials />,
    parameter: EVENT_FILTER_PARAMETERS.CREDENTIALS
  }
]

const FilterBar = (props) => {
  const { isOverflowing, overflowItems, containerRef, chipRefs, onSubmit, handlePermissions } = props;
  const classes = useStyles();
  const { t }   = useTranslation();
  
  const components = EVENT_FILTER_ENTITIES.filter(component => handlePermissions(component.name, GET));

  const { state : stateFilter, dispatch } = useContext(EventsFilterContext);
  const [searchParams, setSearchParams] = useParams(initialState);
  
  const { state : locationState }  = useContext(LocationContext);
  const { selectedLocations } = locationState;
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [startDate, setStartDate] = useState(searchParams.from);
  const [endDate, setEndDate] = useState(searchParams.until);
  const [searchContent, setSearchContent] = useState(searchParams.keyword);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const searchOrder = EVENT_FILTER_COMPONENT_ORDER.indexOf(EVENT_FILTER_COMPONENTS.SEARCH);
  const dateRangeOrder = EVENT_FILTER_COMPONENT_ORDER.indexOf(EVENT_FILTER_COMPONENTS.DATE_RANGE);

  const handleSearchEnable = () => {
    setIsSearchEnabled((prev) => !prev);
    setKeyword('');
  };

  const handleClearKeyword = () => {
    setKeyword('');
  };

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    setKeyword('');
    setIsSearchEnabled(false);
    setSearchContent(keyword);
    setIsSubmitDisabled(false);
  };

  const handleRemoveSearch = () => {
    setSearchContent('');
  };

  const hanldeEventDateRangeChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  }

  const isArrayLocationsEqual = (selectedLocations, prevLocations) => {
    if (!Array.isArray(selectedLocations) || !Array.isArray(prevLocations)) {
      return false;
    }
  
    if (selectedLocations.length !== prevLocations.length) {
      return false;
    }
  
    return selectedLocations.every((location, index) => location.id === prevLocations[index].id);
  };

  /* This determines whether any of the event filter states
  have been updated based on the initial state (URL Parameters). */
  const hasEventStatesBeenUpdated = useCallback(() => {
    const filterParams = {
      subTypes: parseJSONParam(searchParams.subTypes),
      locationIds: parseJSONParam(searchParams.locationIds),
      accessPoints: parseJSONParam(searchParams.accessPoints),
      deviceIds: parseJSONParam(searchParams.deviceIds),
      credentialNumbers: parseJSONParam(searchParams.credentialNumbers),
    };

    if (searchContent !== searchParams.keyword) {
      return true;
    }

    if (startDate !== searchParams.from) {
      return true;
    }
  
    if (endDate !== searchParams.until) {
      return true;
    }

    if (filterParams.subTypes && !isArrayLocationsEqual(stateFilter.subTypes, filterParams.subTypes)) {
      return true;
    }
  
    let locations = filterParams.locationIds;
    if (typeof locations === 'string') {
      locations = locations ? [locations] : [];
    }

    if (!locations?.length) {
      locations = getInitialLocations(selectedLocations);
    }

    if (locations && !isArrayLocationsEqual(stateFilter.locationIds, locations)) {
      return true;
    }
  
    if (filterParams.accessPoints && !isArrayLocationsEqual(stateFilter.accessPoints, filterParams.accessPoints)) {
      return true;
    }
  
    if (filterParams.deviceIds && !isArrayLocationsEqual(stateFilter.deviceIds, filterParams.deviceIds)) {
      return true;
    }
  
    if (filterParams.credentialNumbers && !isArrayLocationsEqual(stateFilter.credentialNumbers, filterParams.credentialNumbers)) {
      return true;
    }
  
    return false;
  }, [searchParams, stateFilter, selectedLocations, searchContent, startDate, endDate]);
  
  const handleActionButtonEnable = useCallback(() => {
    const hasBeenUpdated = hasEventStatesBeenUpdated();
    setIsSubmitDisabled(!hasBeenUpdated);
  }, [hasEventStatesBeenUpdated]);

  const handleSubmit = () => {
    // Convert filter configurations to string and update the URL parameters
    const stateValues = {
      locationIds: JSON.stringify(stateFilter.locationIds),
      subTypes: JSON.stringify(stateFilter.subTypes),
      accessPoints: JSON.stringify(stateFilter.accessPoints),
      deviceIds: JSON.stringify(stateFilter.deviceIds),
      credentialNumbers: JSON.stringify(stateFilter.credentialNumbers),
    }
    // Remove the value of an empty properties
    let newParams = Object.fromEntries(
      Object.entries(stateValues).map(([key, value]) => {
        const parsedValue = JSON.parse(value);
        if (!parsedValue || parsedValue.length === 0) {
          return [key, ''];
        }
  
        return [key, value];
      })
    );
    newParams = {
      ...initialState,
      ...newParams,
      keyword: searchContent, 
      from: startDate, 
      until: endDate, 
      page: EVENT_FILTER_COMPONENT_PAGINATION.PAGE
    }
    delete newParams.listType;
    delete newParams.types;
    delete newParams.events;

    setSearchParams(newParams);

    const newStateFilter = { 
      ...stateFilter, 
      from: startDate,
      until: endDate, 
      keyword: searchContent,
      page: EVENT_FILTER_COMPONENT_PAGINATION.PAGE
    }

    // Store new filter configurations on the state reducer to be used on the API Call
    dispatch({ type: ADD_FILTER, payload: newStateFilter });
    onSubmit(newStateFilter);
  }

  useEffect(() => {
    handleActionButtonEnable();
  }, [handleActionButtonEnable, stateFilter]);
  
  return (
    <Paper component="form" onSubmit={handleSubmitSearch} className={classes.root}>
      <Box className={classes.inputContainer} ref={containerRef}>
        {searchContent ? (
          <Box className={classes.chipContiner}>
            <Chip
              handleDelete={handleRemoveSearch}
              containerStyle={classes.searchContent}
              icon={<Search />}
              ref={(ref) => (chipRefs.current[searchOrder] = ref)}
            >
              <Typography noWrap>{searchContent}</Typography>
            </Chip>
          </Box>
        ) : (
          <IconButton
            onClick={handleSearchEnable}
            className={classes.iconButton}
            aria-label="menu"
            ref={(ref) => (chipRefs.current[0] = ref)}
          >
            <Search />
          </IconButton>
        )}
        {!isSearchEnabled && (
          <Box className={classes.chipContiner}>
            <EventFilterCalendar 
              startDate={startDate} 
              endDate={endDate} 
              onEventDateRangeChange={hanldeEventDateRangeChange}
              ref={(ref) => (chipRefs.current[dateRangeOrder] = ref)}
            />
            <div className={classes.chipEntities}>
              {components.map((entity, index) => (
                React.cloneElement(entity.component, {
                  key: index,
                  overflowItems: overflowItems,
                  chipRefs: chipRefs,
                })
              ))}
            </div>
          </Box>
        )}
        {isSearchEnabled && (
          <InputBase
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
            className={classes.input}
            placeholder={t('events-page-filter.searchLabel')}
            inputProps={{ 'aria-label': 'Search Events' }}
          />
        )}
      </Box>
      <ActionButtons
        isOverflowing={isOverflowing}
        overflowItems={overflowItems}
        isSubmitDisabled={isSubmitDisabled}
        isSearchEnabled={isSearchEnabled}
        keyword={keyword}
        handleClearKeyword={handleClearKeyword}
        eventFilterEntities={components}
        handleSubmit={handleSubmit}
      />
    </Paper>
  );
};

export default FilterBar;