import clsx from 'clsx';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../context/authContext';
import { EventsFilterContext } from '../../../context/eventsFIlterContext';
import { ADD_FILTER } from '../../../reducer/eventsFilterReducer';
import { EVENT_FILTER_COMPONENT_ORDER, EVENT_FILTER_COMPONENTS } from '../../../utility/constants';
import { getAllEventFilterChildren, getEventInitialLabel, getInitialEventFilterValues } from '../../../utility/event';
import { formatEventLocationsSubAreas, nestedEventLocations } from '../../../utility/helper';
import Chip from '../chip';
import Dropdown from '../drop-down';
import useStyles from './styles';

const Locations = (props) => {
  const { overflowItems, chipRefs } = props;
  
  const { t } = useTranslation();

  const { state } = useContext(AuthContext);
  const { administrator } = state; 
  const { areaLocations } = administrator;
  
  const { state: stateFilter, dispatch } = useContext(EventsFilterContext);

  const { locationIds: locationsState = [] } = stateFilter;

  const name = EVENT_FILTER_COMPONENTS.LOCATIONS;
  const componentOrder = EVENT_FILTER_COMPONENT_ORDER.indexOf(name);
  
  // Prepare location data
  areaLocations.sort((a, b) => a.name.localeCompare(b.name));
  const locationData = formatEventLocationsSubAreas(areaLocations);
  nestedEventLocations(locationData);
  
  let initialLocations = getInitialEventFilterValues(locationsState);
  
  const defaultLabel = t(`events-page-filter.${name}`);
  const initialLabel = getEventInitialLabel(initialLocations, defaultLabel, t('events-page-filter.multipleLocationsLabel', { count: initialLocations?.length }));
  const initialList = { parent: null, items: locationData, isMain: true };

  const classes = useStyles();
  const [status, setStatus] = useState(!!initialLocations.length);
  const [selectedValues, setSelectedValues] = useState(initialLocations);
  const [list, setList] = useState(initialList);
  const [tempList, setTempList] = useState(initialList);
  const [label, setLabel] = useState(initialLabel);

  const handleDelete = () => {
    setSelectedValues([]);
    setLabel(defaultLabel);
    dispatch({ type: ADD_FILTER, payload: { ...stateFilter, locationIds: [] } });
    setStatus(false);
  }

  const handleSelect = (value, children = []) => {
    setSelectedValues(prevValues => {
      let updatedSelections = [...prevValues];
      const allChildValues = getAllEventFilterChildren(children);
      const hasChildren = children.length > 0;
      const areAllChildrenSelected = allChildValues.length && 
        allChildValues.every(child => prevValues.find(item => child.id === item.id));
      const isValueSelected = updatedSelections.find(item => value.id === item.id);

      if (hasChildren && areAllChildrenSelected) {
        updatedSelections = updatedSelections.filter(item => 
          !allChildValues.find(child => child.id === item.id));
      } else if (isValueSelected && !hasChildren) {
        updatedSelections = updatedSelections.filter(item => item.id !== value.id);
      } else if (!isValueSelected && !hasChildren) {
        updatedSelections.push(value);
      } else {
        updatedSelections = [...prevValues, ...allChildValues];
        updatedSelections = Array.from(new Map(updatedSelections.map(item => [item.id, item])).values());
      }
      
      return updatedSelections;
    });
  }

  const handleSearch = useCallback((keyword) => {
    if (!keyword) {
      setList(tempList);
      return;
    }
    const filteredItems = list.items.filter(item => 
      item.label.toLowerCase().includes(keyword.toLowerCase())
    );
    setList(prev => ({ ...prev, items: filteredItems }));
  }, [list.items, tempList]);

  const handleList = useCallback((item) => {
    setList(item);
    setTempList(item);
  }, []);

  const handleClose = () => {
    const resetLocations = getInitialEventFilterValues(locationsState)

    const newLabel = getEventInitialLabel(resetLocations, defaultLabel,  t('events-page-filter.multipleLocationsLabel', { count: resetLocations?.length }));
    
    setList(initialList);
    setTempList(initialList);
    setSelectedValues(resetLocations);
    setLabel(newLabel);
    setStatus(!!resetLocations.length);
  };

  const isArrayLocationsEqual = (selectedLocations, prevLocations) => {
    if (selectedLocations.length !== prevLocations.length) {
      return false;
    }

    return selectedLocations.every((location, index) => location.id === prevLocations[index].id);
  };

  const handleSubmit = () => {
    const hasChanged = !isArrayLocationsEqual(selectedValues, initialLocations);
    if (!hasChanged) {
      return;
    }

    const locations = selectedValues.map(value => ({
      id: value.id,
      label: value.label,
      children: []
    }));
    
    dispatch({ type: ADD_FILTER, payload: { ...stateFilter, locationIds: locations } });
    
    const newLabel = selectedValues.length > 1 
      ? t('events-page-filter.multipleLocationsLabel', { count: selectedValues?.length })
      : selectedValues.length === 1 
        ? selectedValues[0].label 
        : defaultLabel;

    setLabel(newLabel);
    setStatus(true);
  }

  const handleShowMoreValue = useCallback(() => {
    setSelectedValues(locationsState);
    const newLabel = getEventInitialLabel(
      locationsState, defaultLabel, t('events-page-filter.multipleLocationsLabel', { count: locationsState?.length }));
    setLabel(newLabel);
    setStatus(!!locationsState.length);
  }, [t, locationsState, defaultLabel])

  useEffect(() => {
    handleShowMoreValue();
  }, [handleShowMoreValue, locationsState]);

  return (
    <Chip
      handleDelete={handleDelete}
      showCloseIcon={status}
      containerStyle={clsx(
        !status && classes.inactiveChip,
        overflowItems?.includes(componentOrder) && classes.hiddenChip,
        !chipRefs && classes.overflow
      )}
      status={selectedValues.length}
      ref={(ref) => {
        if (ref && chipRefs) chipRefs.current[componentOrder] = ref;
      }}
    >
      <Dropdown
        label={label}
        list={list}
        handleSubmit={handleSubmit}
        handleSearch={handleSearch}
        handleScroll={() => {}}
        backLabel={t('events-page-filter.goBacktoMainLocations')}
        handleSelect={handleSelect}
        selectedValues={selectedValues}
        handleList={handleList}
        handleClose={handleClose}
      />
    </Chip>
  );
};

export default Locations;