import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  chip: {
    maxWidth: theme.spacing(29.125),
    minWidth: theme.spacing(10),
    borderRadius: theme.spacing(1),
    background: colors.PRIMARY_LIGHT,
    color: colors.PRIMARY_TEXT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
    marginRight: theme.spacing(0.75),
    '& .MuiChip-deleteIcon': {
      color: colors.PRIMARY_TEXT,
      width: theme.spacing(4.25),
    },
    '& .MuiChip-icon': {
      width: theme.spacing(2.25),
    },
    '& .MuiTypography-root': {
      fontSize: theme.spacing(1.75)
    },
    '& .MuiChip-label': {
      padding: 0,
      width: '100%'
    },
    transition: 'background 0.3s ease-in-out',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    flex: 1, 
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
    '& .MuiIconButton-root': {
      padding: theme.spacing(0),
      marginLeft: theme.spacing(1)
    }
  },
  deleteBox: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(0.5),
    '& .MuiSvgIcon-root': {
      width: theme.spacing(2.25),
    }
  },
  divider: {
    height: '140%',
    marginRight: theme.spacing(0.5),
    backgroundColor: colors.WHITE,
  },
}));
